import { VueEditor } from "vue2-editor";
import { mapGetters } from "vuex";
import fitService from "../../api/fit.service";
export default {
  name: "branch-info-dialog",
  components: { VueEditor },
  props: [],
  data() {
    return {
      dialog: false,
      number: 1234,
      contactEmail: "ramaogeti448@gmail.com",
      toEmails: [],
      items: [],
      search: null,
      message: "",
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["code-block"],
      ],
      isServiceRecords: false,
      branchInfo: {},
      phoneNumber: "",
      email: "",
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(["CRId", "branchDetails"]),
  },
  mounted() {},
  watch: {
    toEmails(val, prev) {
      if (val.length === prev.length) return;
      this.toEmails = val.map((v) => {
        // v= v.trim();
        // if (typeof v === "string" && v!="") {
        //   // v = {
        //   //   name: v,
        //   // };
        // }
        return v;
      });
    },
  },
  methods: {
    openDialog() {
      this.branchInfo = {};
      this.dialog = true;
      if (this.branchDetails != {}) {
        this.branchInfo = this.branchDetails;
      } else {
        this.branchInfo = {};
      }
      if (this.branchInfo && this.branchInfo.email) {
        this.toEmails.push(this.branchInfo.email);
      }
    },
    closeDialog() {
      this.dialog = false;
      this.isServiceRecords = false;
      this.toEmails = [];
      this.message = "";
    },
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1
      );
    },
    sendServiceRecords() {
      this.isServiceRecords = true;
    },
    async sendEmails() {
      if (this.toEmails && this.toEmails.length > 0) {
        let iserror = false;
        this.toEmails.filter((e) => {
          if (
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              e
            ) === false
          ) {
            this.$toast.warning("Email should be valid email");
            // return;
            iserror = true;
          }
        });
        let branchId = "";
        if(this.branchInfo && this.branchInfo.cbId){
          branchId = this.branchInfo.cbId;
        }else{
          branchId = "";
        }
        if (!iserror) {
          let payload = {
            toEmailAddress: this.toEmails.join(","),
            message: this.message,
            brnachId: branchId
          };
          this.isLoading = true;
          try {
            let response = await fitService.sendEmail(this.CRId, payload);
            if (response.isSuccess) {
              this.isLoading = false;
              this.$toast.success(response.systemMessage);
              this.closeDialog();
            } else {
              this.isLoading = false;
              this.$toast.error(response.systemMessage);
              this.closeDialog();
            }
          } catch (err) {
            this.isLoading = false;
            this.closeDialog();
          }
        }
      }
    },
  },
};
