<template>
  <v-app>
    <router-view :key="$route.fullPath" />
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
// import config from "../src/config";
import store from "../src/store";
// import tokenAuthService from "../src/api/tokenAuthenticationService";
// import router from "../src/router";
export default {
  name: "App",
  components: {},
  data() {
    return {
      isUserLoggedin: false
    };
  },
  created() {
    //console.log("TEST_TOKEN: ", config.TEST_TOKEN);
  },
  mounted() {
    this.isUserLoggedin = store.getters.token;
  },
  computed: {
    ...mapGetters(["themeShow", "navbarShow"]),
    // cssProps() {
    //   if (
    //     !this.themeShow ||
    //     !this.themeShow.primary ||
    //     !this.themeShow.secondary ||
    //     !this.themeShow.warning ||
    //     !this.themeShow.accent
    //   ) {
    //     this.setTheme();
    //   } else {
    //     this.setVuitifyThemes();
    //   }
    //   var themeColors = {};
    //   Object.keys(this.$vuetify.theme.themes.light).forEach(color => {
    //     themeColors[`--v-${color}`] = this.$vuetify.theme.themes.light[color];
    //   });
    //   return themeColors;
    // }
  },
  watch: {
    // isUserLoggedin(isLogged) {
    //   if (isLogged) {
    //     this.setAuthTimers();
    //   }
    // },
    $route() {
      //scroll to top of the page
      window.scrollTo(0, 0);
      this.isUserLoggedin = store.getters.token;
      // //Resetting manually as app.vue just get remounted even on logout
      // if (!this.isUserLoggedin) {
      //   clearInterval(this.refreshInterval);
      //   return;
      // }
    }
  },
  methods: {
    // setAuthTimers() {
    //   this.refreshInterval = setInterval(() => {
    //     //console.log(config.authTokenInterval);
    //     this.callTokenUpdate();
    //   }, parseInt(config.AUTH_TOKEN_INTERVAL));
    // },
    // callTokenUpdate() {
    //   var refershObject = {
    //     grant_type: "refresh_token",
    //     refresh_token: store.getters.refreshToken,
    //     username: store.getters.user
    //   };
    //   tokenAuthService.getAccessTokenByRefreshToken(
    //     refershObject,
    //     response => {
    //       store.commit("SET_ACCESS_TOKEN", response.access_token);
    //       store.commit("SET_REFRESH_TOKEN", response.refresh_token);
    //       store.commit("SET_TOKEN_EXPIRY_TIME", response.expires_at);
    //       // this.$store.commit("updateisTokenRefreshing", false);
    //     },
    //     errorResponse => {
    //       console.log(errorResponse);
    //       // this.$store.commit("updateisTokenRefreshing", false);
    //       this.$toast.error("Session expired, please kindly login again");
    //       store.dispatch("LogOut");
    //       router.push("/signin");
    //     }
    //   );
    // },
    // openModal() {
    //   this.$refs.modal.showModal();
    // }
  }
};
</script>
