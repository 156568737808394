<template>
  <v-main class="v-main-custom">
    <div class="v-main-custom-container">
      <transition name="fade-transform" mode="out-in">
        <keep-alive>
          <router-view />
        </keep-alive>
      </transition>
    </div>
  </v-main>
</template>

<script>
export default {
  name: "TheLayoutContent",
  data: () => ({}),
};
</script>
<style scoped lang="scss">
@import "../../assets/styles/variables.scss";

.v-main-custom {
  height: 100vh;
  width: 100%;
  display: flex;
}
.v-main-custom::after {
  content: "";
  // background-image: url(/img/icons/fit-back.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  // position: absolute;
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.1;
}
.v-main-custom-container {
  position: relative;
  z-index: 1;
  // height:100vh;
}
</style>
