<template>
  <div class="v-application--wrap">
    <the-layout-drawer />
    <the-layout-toolbar class="main-header" />
    <the-layout-content />
    <the-layout-footer />
  </div>
</template>
<style scoped>
/* .theme--light.v-app-bar.v-toolbar.v-sheet{
    font-family: "roboto", sans-serif;
    background: linear-gradient(120deg, #556cdc, #128bfc, #18bef1);
} */
.main-header {
  background-color: rgb(56, 58, 60) !important;
  border-bottom: 1px solid #eef1f3 !important;
  overflow-x: auto;
  overflow-y: hidden;
  height: 65px !important;
}
</style>
<script>
import TheLayoutDrawer from "./TheLayoutDrawer.vue";
import TheLayoutToolbar from "./TheLayoutToolbar.vue";
import TheLayoutContent from "./TheLayoutContent.vue";
import TheLayoutFooter from "./TheLayoutFooter.vue";

export default {
  name: "TheLayout",
  components: {
    TheLayoutDrawer,
    TheLayoutToolbar,
    TheLayoutContent,
    TheLayoutFooter,
  },
  data: () => ({}),
};
</script>
