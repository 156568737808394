import axios from "../api/axios";

export default {
  async execute(method, resource, data) {
    return axios.userEndPoint({
      method,
      url: resource,
      data
    }).then(req => {
      return req.data;
    });
  },
  getUsers() {
    return this.execute("get", "/users");
  },
  createUser(payload) {
    return this.execute("post", "/users", payload);
  },
  updateUser(payload, userId) {
    return this.execute("put", `/user/${userId}`, payload);
  },
  getUser(userId) {
    return this.execute("get", `/Users/${userId}`);
  },
  setdefaultCustomer(payload) {
    return this.execute("put", `/users/defaultCustomer`, payload);
  }
};
