import Vue from "vue";
import VueRouter from "vue-router";
import authRouter from "./modules/auth";
import Layout from "@/views/layout/TheLayout.vue";
// import { validateAccess } from "../auth";

Vue.use(VueRouter);

export const constantRoutes = [
  {
    path: "/",
    name: "home",
    component: Layout,
    children: [
      {
        path: "/",
        component: { render: (c) => c("router-view") },
        // beforeEnter: validateAccess,
        children: [
          {
            path: "/home",
            alias: "/",
            component: () => import("@/components/HomePage/index"),
            name: "home",
            meta: {
              title: "Home",
              icon: "mdi-home-outline",
              affix: true,
            },
          },
        ],
      },
      // {
      //   path: "/discovery",
      //   name: "discovery",
      //   alias: "/",
      //   component: { render: (c) => c("router-view") },
      //   children: [
      //     {
      //       path: "/discovery",
      //       alias: "/",
      //       component: () => import("@/components/Discovery/index"),
      //       name: "discovery",
      //       meta: {
      //         title: "Discovery",
      //         // roles: ["Super Admin", 'Admin',"install admin", "sales"],
      //         icon: "mdi-find-replace",
      //         affix: true,
      //       },
      //     },
      //   ],
      // },
      // {
      //   path: "/assetdetails",
      //   name: "asset details",
      //   alias: "/",
      //   component: { render: (c) => c("router-view") },
      //   children: [
      //     {
      //       path: "/assetdetails",
      //       alias: "/",
      //       component: () => import("@/components/AssetDetails/index"),
      //       name: "asset details",
      //       meta: {
      //         title: "Asset Details",
      //         icon: "mdi-card-search",
      //         affix: true,
      //       },
      //     },
      //   ],
      // },
      // {
      //   path: "/worknearme",
      //   name: "work near me",
      //   alias: "/",
      //   component: { render: (c) => c("router-view") },
      //   children: [
      //     {
      //       path: "/worknearme",
      //       alias: "/",
      //       component: () => import("@/components/WorkNearMe/index"),
      //       name: "work near me",
      //       meta: {
      //         title: "Work Near Me",
      //         icon: "mdi-google-maps",
      //         affix: true,
      //       },
      //     },
      //   ],
      // },
      // {
      //   path: "/statusandreporting",
      //   name: "Status and Reporting",
      //   alias: "/",
      //   component: { render: (c) => c("router-view") },
      //   children: [
      //     {
      //       path: "/statusandreporting",
      //       alias: "/",
      //       component: () => import("@/components/StatusAndReporting/index"),
      //       name: "Status and Reporting",
      //       meta: {
      //         title: "Status And Reporting",
      //         icon: "mdi-content-copy",
      //         affix: true,
      //       },
      //     },
      //   ],
      // },
      // {
      //   path: "/searchforwork",
      //   name: "search for work",
      //   alias: "/",
      //   component: { render: (c) => c("router-view") },
      //   children: [
      //     {
      //       path: "/searchforwork",
      //       alias: "/",
      //       component: () => import("@/components/SearchForWork/index"),
      //       name: "search for work",
      //       meta: {
      //         title: "Search For Work",
      //         // roles: ["Super Admin", 'Admin',"install admin", "sales"],
      //         icon: "mdi-home",
      //         affix: true,
      //       },
      //     },
      //   ],
      // },
      // {
      //   path: "/viewsearch/:Id",
      //   name: "view search",
      //   alias: "/",
      //   component: { render: (c) => c("router-view") },
      //   children: [
      //     {
      //       path: "/viewsearch/:Id",
      //       alias: "/",
      //       component: () => import("@/components/ViewSearch/index"),
      //       name: "view search",
      //       meta: {
      //         title: "View Search",
      //         // roles: ["Super Admin", 'Admin',"install admin", "sales"],
      //         icon: "mdi-home",
      //         affix: true,
      //       },
      //     },
      //   ],
      // },
      // {
      //   path: "/editpictures/:Id",
      //   name: "edit pictures",
      //   alias: "/",
      //   component: { render: (c) => c("router-view") },
      //   children: [
      //     {
      //       path: "/editpictures/:Id",
      //       alias: "/",
      //       component: () => import("@/components/EditPictures/index"),
      //       name: "edit pictures",
      //       meta: {
      //         title: "Edit Pictures",
      //         icon: "mdi-home",
      //         affix: true,
      //       },
      //     },
      //   ],
      // },
      // {
      //   path: "/assignedwork",
      //   name: "assigned work",
      //   alias: "/",
      //   component: { render: (c) => c("router-view") },
      //   children: [
      //     {
      //       path: "/assignedwork",
      //       alias: "/",
      //       component: () => import("@/components/AssignWork/index"),
      //       name: "assigned work",
      //       meta: {
      //         title: "Assigned Work",
      //         // roles: ["Super Admin", 'Admin',"install admin", "sales"],
      //         icon: "mdi-home",
      //         affix: true,
      //       },
      //     },
      //   ],
      // },
      // {
      //   path: "/captureassetpicture/:Id",
      //   name: "Capture Asset Picture",
      //   alias: "/",
      //   component: { render: (c) => c("router-view") },
      //   children: [
      //     {
      //       path: "/captureassetpicture/:Id",
      //       alias: "/",
      //       component: () => import("@/components/CaptureAssetIdPicture/index"),
      //       name: "Capture Asset Picture",
      //       meta: {
      //         title: "Capture Asset Picture",
      //         // roles: ["Super Admin", 'Admin',"install admin", "sales"],
      //         icon: "mdi-home",
      //         affix: true,
      //       },
      //     },
      //   ],
      // },
      // {
      //   path: "/capturetuserialpicture/:Id",
      //   name: "Capture TU Serial Picture",
      //   alias: "/",
      //   component: { render: (c) => c("router-view") },
      //   children: [
      //     {
      //       path: "/capturetuserialpicture/:Id",
      //       alias: "/",
      //       component: () =>
      //         import("@/components/CaptureTuSerialPicture/index"),
      //       name: "Capture TU Serial Picture",
      //       meta: {
      //         title: "Capture TU Serial Picture",
      //         // roles: ["Super Admin", 'Admin',"install admin", "sales"],
      //         icon: "mdi-home",
      //         affix: true,
      //       },
      //     },
      //   ],
      // },
      // {
      //   path: "/validate/:Id",
      //   name: "Validate",
      //   alias: "/",
      //   component: { render: (c) => c("router-view") },
      //   children: [
      //     {
      //       path: "/validate/:Id",
      //       alias: "/",
      //       component: () => import("@/components/Validate/index"),
      //       name: "Validate",
      //       meta: {
      //         title: "Validate",
      //         // roles: ["Super Admin", 'Admin',"install admin", "sales"],
      //         icon: "mdi-home",
      //         affix: true,
      //       },
      //     },
      //   ],
      // },
      // {
      //   path: "/installverificationcheck/:Id",
      //   name: "Install Verification Check",
      //   alias: "/",
      //   component: { render: (c) => c("router-view") },
      //   children: [
      //     {
      //       path: "/installverificationcheck/:Id",
      //       alias: "/",
      //       component: () =>
      //         import("@/components/InstallVerificationCheck/index"),
      //       name: "Install Verification Check",
      //       meta: {
      //         title: "Install Verification Check",
      //         icon: "mdi-home",
      //         affix: true,
      //       },
      //     },
      //   ],
      // },
      // {
      //   path: "/installpictures/:Id",
      //   name: "Install Pictures",
      //   alias: "/",
      //   component: { render: (c) => c("router-view") },
      //   children: [
      //     {
      //       path: "/installpictures/:Id",
      //       alias: "/",
      //       component: () => import("@/components/InstallPictures/index"),
      //       name: "Install Pictures",
      //       meta: {
      //         title: "Install Pictures",
      //         icon: "mdi-home",
      //         affix: true,
      //       },
      //     },
      //   ],
      // },
      // {
      //   path: "/complete/:Id",
      //   name: "Complete Screen",
      //   alias: "/",
      //   component: { render: (c) => c("router-view") },
      //   children: [
      //     {
      //       path: "/complete/:Id",
      //       alias: "/",
      //       component: () => import("@/components/CompleteScreen/index"),
      //       name: "Complete Screen",
      //       meta: {
      //         title: "Complete Screen",
      //         icon: "mdi-home",
      //         affix: true,
      //       },
      //     },
      //   ],
      // },
    ],
  },
  {
    path:"/",
    name:"Safety & Deployment Info",
    component: Layout,
    // beforeEnter: validateAccess,
    children:[
      {
        path: "/Safety-DeploymentInfo",
        component: () => import("@/components/SafetyAndDeploymentInfo/index"),
        name: "Safety & Deployment Info",
        meta: {
          title: "Safety & Deployment Info",
          icon: "mdi-television-guide",
          affix: true
        }
      }
    ]
  },
  // {
  //   path:"/",
  //   name:"Sync Settings",
  //   component: Layout,
  //   // beforeEnter: validateAccess,
  //   children:[
  //     {
  //       path: "/sync-settings",
  //       component: () => import("@/components/SyncSettings/index"),
  //       name: "Sync Settings",
  //       meta: {
  //         title: "Sync Settings",
  //         icon: "mdi-settings",
  //         affix: true
  //       }
  //     }
  //   ]
  // },
  {
    path:"/",
    name:"discovery",
    // component: { render: c => c("router-view") },
    component: Layout,
    // beforeEnter: validateAccess,
    children:[
      // {
      //   path: "/sync-settings",
      //   component: () => import("@/components/SyncSettings/index"),
      //   name: "Sync Settings",
      //   meta: {
      //     title: "Sync Settings",
      //     icon: "mdi-cog",
      //     affix: true
      //   }
      // },
      {
        path: "/discovery",
        component: () => import("@/components/Discovery/index"),
        name: "discovery",
        meta: {
          title: "Discovery",
          icon: "mdi-fine-replace",
          affix: true
        }
      },
      {
        path: "/searchforwork",
        component: () => import("@/components/SearchForWork/index"),
            name: "search for work",
            meta: {
              title: "Search For Work",
              // roles: ["Super Admin", 'Admin',"install admin", "sales"],
              icon: "mdi-home-ouline",
              affix: true,
            },
      },
      {
        path: "/viewsearch/:Id",
            component: () => import("@/components/ViewSearch/index"),
            name: "view search",
            meta: {
              title: "View Search",
              // roles: ["Super Admin", 'Admin',"install admin", "sales"],
              icon: "mdi-home-outline",
              affix: true,
            },
      },
      {
        path: "/viewsearchcompleted/:Id",
            component: () => import("@/components/ViewSearchCompleted/index"),
            name: "view search completed",
            meta: {
              title: "View Search Completed",
              // roles: ["Super Admin", 'Admin',"install admin", "sales"],
              icon: "mdi-home-outline",
              affix: true,
            },
      },
      {
        path: "/captureassetpicture/:Id",
            component: () => import("@/components/CaptureAssetIdPicture/index"),
            name: "Capture Asset Picture",
            meta: {
              title: "Capture Asset Picture",
              // roles: ["Super Admin", 'Admin',"install admin", "sales"],
              icon: "mdi-home-outline",
              affix: true,
            },
            
      },
      {
        path: "/capturetuserialnumber/:Id",
            component: () =>
              import("@/components/TUSerialNumber/index"),
            name: "TUSerial Number",
            meta: {
              title: "TUSerial Number",
              // roles: ["Super Admin", 'Admin',"install admin", "sales"],
              icon: "mdi-home",
              affix: true,
            },
      },
      {
        path: "/capturetuserialmachine/:Id",
            component: () =>
              import("@/components/TUSerialMachinePictures/index"),
            name: "TUSerial-Machine",
            meta: {
              title: "TUSerial-Machine",
              // roles: ["Super Admin", 'Admin',"install admin", "sales"],
              icon: "mdi-home",
              affix: true,
            },
      },
      {
        path: "/capturetuserialpicture/:Id",
            component: () =>
              import("@/components/CaptureTuSerialPicture/index"),
            name: "Capture TU Serial Picture",
            meta: {
              title: "Capture TU Serial Picture",
              // roles: ["Super Admin", 'Admin',"install admin", "sales"],
              icon: "mdi-home",
              affix: true,
            },
      },
      {
        path: "/capturedefitmachinepicture/:Id",
            component: () => import("@/components/DefitMachinePicture/index"),
            name: "Defit Machine Picture",
            meta: {
              title: "Defit Machine Picture",
              // roles: ["Super Admin", 'Admin',"install admin", "sales"],
              icon: "mdi-home",
              affix: true,
            },
            
      },
      {
        path: "/capturedefitwiringpicture/:Id",
            component: () => import("@/components/WiringPicture/index"),
            name: "Defit Wiring Picture",
            meta: {
              title: "Defit Wiring Picture",
              // roles: ["Super Admin", 'Admin',"install admin", "sales"],
              icon: "mdi-home",
              affix: true,
            },
            
      },
      {
        path: "/relayrepairpictures/:Id",
            component: () => import("@/components/RelayRepairPictures/index"),
            name: "Relay Replacement Pictures",
            meta: {
              title: "Relay Replacement Pictures",
              icon: "mdi-home",
              affix: true,
            },
      },
      {
        path: "/inspectionpictures/:Id",
            component: () => import("@/components/InspectionPictures/index"),
            name: "Inspection Pictures",
            meta: {
              title: "Inspection Pictures",
              icon: "mdi-home",
              affix: true,
            },
      },
      {
        path: "/validate/:Id",
            component: () => import("@/components/Validate/index"),
            name: "Validate",
            meta: {
              title: "Validate",
              // roles: ["Super Admin", 'Admin',"install admin", "sales"],
              icon: "mdi-home",
              affix: true,
            },
      },
      {
        path: "/installverificationcheck/:Id",
       
            component: () =>
              import("@/components/InstallVerificationCheck/index"),
            name: "Install Verification Check",
            meta: {
              title: "Install Verification Check",
              icon: "mdi-home",
              affix: true,
            },
      },
      {
        path: "/installvalidation/:Id",
       
            component: () =>
              import("@/components/InstallValidation/index"),
            name: "Install Validation",
            meta: {
              title: "Install Validation",
              icon: "mdi-home",
              affix: true,
            },
      },
      {
        path: "/installvalidationiframe/:Id",
       
            component: () =>
              import("@/components/InstallVerificationIframe/index"),
            name: "Install Validation Iframe",
            meta: {
              title: "Install Validation Iframe",
              icon: "mdi-home",
              affix: true,
            },
      },
      {
        path: "/installpictures/:Id",
            component: () => import("@/components/InstallPictures/index"),
            name: "Install Pictures",
            meta: {
              title: "Install Pictures",
              icon: "mdi-home",
              affix: true,
            },
      },
      {
        path: "/complete/:Id/",
            component: () => import("@/components/CompleteScreen/index"),
            name: "Complete Screen",
            meta: {
              title: "Complete Screen",
              icon: "mdi-home",
              affix: true,
            },
      },
      {
        path: "/defit-complete/:Id",
        component: () => import("@/components/DefitComplete/index"),
        name: "Defit Complete",
        meta: {
          title: "Defit Complete",
          icon: "mdi-help-circle",
          affix: true
        }
      },
      {
        path: "/Inspection-complete/:Id",
        component: () => import("@/components/InspectionComplete/index"),
        name: "Inspection Complete",
        meta: {
          title: "Inspection Complete",
          icon: "mdi-help-circle",
          affix: true
        }
      },
      {
        path: "/relayrepair-complete/:Id",
        component: () => import("@/components/RelayRepairComplete/index"),
        name: "Relay Replacement Complete",
        meta: {
          title: "Relay Replacement Complete",
          icon: "mdi-help-circle",
          affix: true
        }
      },
      {
        path: "/editpictures/:Id",
            component: () => import("@/components/EditPictures/index"),
            name: "edit pictures",
            meta: {
              title: "Edit Pictures",
              icon: "mdi-home",
              affix: true,
            },
      },
      {
        path: "/defiteditpictures/:Id",
            component: () => import("@/components/DefitEditPictures/index"),
            name: "Defit Edit pictures",
            meta: {
              title: "Defit Edit Pictures",
              icon: "mdi-home",
              affix: true,
            },
      },
      {
        path: "/assignedwork",
            component: () => import("@/components/AssignWork/index"),
            name: "assigned work",
            meta: {
              title: "Assigned Work",
              // roles: ["Super Admin", 'Admin',"install admin", "sales"],
              icon: "mdi-home",
              affix: true,
            },
      },
      {
        path: "/worknearme",
            component: () => import("@/components/WorkNearMe/index"),
            name: "work near me",
            meta: {
              title: "Work Near Me",
              icon: "mdi-google-maps",
              affix: true,
            },
      },
      {
        path: "/selectbranch",
            component: () => import("@/components/SelectBranch/index"),
            name: "Select a Branch",
            meta: {
              title: "Select a Branch",
              icon: "mdi-source-branch",
              affix: true,
            },
      },
      {
        path: "/branchcheckvalidation/:Id",
            component: () => import("@/components/BranchCheckValidation/index"),
            name: "Branch Check Validation",
            meta: {
              title: "Branch Check Validation",
              icon: "mdi-source-branch-check",
              affix: true,
            },
      },
      {
        path: "/branch-check-review-list/:Id",
            component: () => import("@/components/BranchCheckReviewList/index"),
            name: "Branch Check Review List",
            meta: {
              title: "Branch Check Review List",
              icon: "mdi-checkbox-marked-circle-outline",
              affix: true,
            },
      },
      {
        path: "/branch-check-walk-yard/:Id",
            component: () => import("@/components/BranchCheckWalkYard/index"),
            name: "Branch Check Walk Yard",
            meta: {
              title: "Branch Check Walk Yard",
              icon: "mdi-checkbox-marked-circle-outline",
              affix: true,
            },
      },
      {
        path: "/branchcheck/:Id",
            component: () => import("@/components/BranchCheck/index"),
            name: "Branch Check",
            meta: {
              title: "Branch Check",
              icon: "mdi-checkbox-marked-circle-outline",
              affix: true,
            },
      },
      {
        path: "/statusandreporting",
            component: () => import("@/components/StatusAndReporting/index"),
            name: "Status and Reporting",
            meta: {
              title: "Status And Reporting",
              icon: "mdi-checkbox-marked-outline",
              affix: true,
            },
      },
      {
        path: "/equipmentrecords",
            component: () => import("@/components/EquipmentRecords/index"),
            name: "Equipment Records",
            meta: {
              title: "Equipment Records",
              icon: "mdi-checkbox-marked-outline",
              affix: true,
            },
      },
      {
        path: "/servicerecords",
            component: () => import("@/components/ServiceRecords/index"),
            name: "Service Records",
            meta: {
              title: "Service Records",
              icon: "mdi-hammer-wrench",
              affix: true,
            },
      },
      {
        path: "/viewauditstatus/:Id",
            component: () => import("@/components/viewAUditPage/index"),
            name: "View Audit Page",
            meta: {
              title: "View Audit Page",
              icon: "mdi-checkbox-marked-outline",
              affix: true,
            },
      },
      {
        path: "/assetdetails",
            component: () => import("@/components/AssetDetails/index"),
            name: "asset details",
            meta: {
              title: "Asset Details",
              icon: "mdi-card-search",
              affix: true,
            },
      },
      {
        path: "/nruinspection/:Id",
       
            component: () =>
              import("@/components/NruInspection/index"),
            name: "NRU Inspection",
            meta: {
              title: "NRU Inspection",
              icon: "mdi-home",
              affix: true,
            },
      },
      {
        path: "/nruverificationcheck/:Id",
       
            component: () =>
              import("@/components/NruVerificationCheck/index"),
            name: "NRU Verification Check",
            meta: {
              title: "NRU Verification Check",
              icon: "mdi-home",
              affix: true,
            },
      },
      {
        path: "/nruinspectionpictures/:Id",
            component: () => import("@/components/NruInspectionPictures/index"),
            name: "NRU Inspection Pictures",
            meta: {
              title: "NRU Inspection Pictures",
              icon: "mdi-home",
              affix: true,
            },
      },
      {
        path: "/nru-inspection-complete/:Id",
        component: () => import("@/components/NruComplete/index"),
        name: "NRU Inspection Complete",
        meta: {
          title: "NRU Inspection Complete",
          icon: "mdi-help-circle",
          affix: true
        }
      },
      // {
      //   path: "/capture-nrurepair-tuserialmachine/:Id",
      //       component: () =>
      //         import("@/components/NruRepairTuSerialMachinePicture/index"),
      //       name: "Nru Repair TUSerial-Machine",
      //       meta: {
      //         title: "Nru Repair TUSerial-Machine",
      //         // roles: ["Super Admin", 'Admin',"install admin", "sales"],
      //         icon: "mdi-home",
      //         affix: true,
      //       },
      // },
      {
        path: "/nru-repair-complete/:Id",
        component: () => import("@/components/NruRepairComplete/index"),
        name: "Nru Repair Complete",
        meta: {
          title: "Nru Repair Complete",
          icon: "mdi-help-circle",
          affix: true
        }
      },
      {
        path: "/nrurepairverificationcheck/:Id",
       
            component: () =>
              import("@/components/NruRepairVerificationCheck/index"),
            name: "Nru Repair Verification Check",
            meta: {
              title: "Nru Repair Verification Check",
              icon: "mdi-home",
              affix: true,
            },
      },
      {
        path: "/nrurepairpictures/:Id",
            component: () => import("@/components/NruRepairPictures/index"),
            name: "NRU Repair Pictures",
            meta: {
              title: "NRU Repair Pictures",
              icon: "mdi-home",
              affix: true,
            },
      },
    ]
  },
  {
    path:"/",
    name:"Activity",
    // component: { render: c => c("router-view") },
    component: Layout,
    // beforeEnter: validateAccess,
    children:[
      {
        path: "/activity",
        component: () => import("@/components/ActivityList/index"),
        name: "Activity",
        meta: {
          title: "Activity",
          icon: "mdi-home-ouline",
          affix: true
        }
      },
      {
        path: "/activity/:Id",
        component: () => import("@/components/ActivityInstallationView/index"),
            name: "Activity Installation View",
            meta: {
              title: "Activity Installation View",
              // roles: ["Super Admin", 'Admin',"install admin", "sales"],
              icon: "mdi-home-ouline",
              affix: true,
            },
      },
    ]
  },
  {
    path:"/",
    name:"Guide",
    component: Layout,
    // beforeEnter: validateAccess,
    children:[
      {
        path: "/guide",
        component: () => import("@/components/Guide/index"),
        name: "Guide",
        meta: {
          title: "Guide",
          icon: "mdi-television-guide",
          affix: true
        }
      }
    ]
  },
  {
    path:"/",
    name:"Support",
    component: Layout,
    // beforeEnter: validateAccess,
    children:[
      {
        path: "/support",
        component: () => import("@/components/Support/index"),
        name: "Support",
        meta: {
          title: "Support",
          icon: "mdi-help-circle",
          affix: true
        }
      },
      {
        path: "/create-ticket",
        component: () => import("@/components/CreateTicket/index"),
        name: "Create Ticket",
        meta: {
          title: "Create Ticket",
          icon: "mdi-help-circle",
          affix: true
        }
      },
    ]
  },
  {
    path: "*",
    name: "not-found",
    component: () => import("@/views/widget/AppError.vue"),
  },

  ...authRouter,
];

export default new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: constantRoutes,
});

export const asyncRoutes = [
  { path: "*", redirect: "/error/404", hidden: true },
];
