/** When your routing table is too long, you can split it into small modules */
import { callback, validateAccess } from "../../auth";
const authRouter = [
    {
      path: '/signin',
      alias: '/login',
      component: () => import('@/views/auth/SignIn.vue'),
      hidden: true,
    },
    {
      path: "/loginredirect",
      alias: "/loginredirect",
      component: () => import("@/views/auth/LoginRedirect.vue"),
      beforeEnter: validateAccess,
      hidden: true,
    },
    {
      path: "/implicit/callback",
      alias: "/implicit/callback",
      component: callback,
      hidden: true,
    }
  ];
  
  export default authRouter;
  