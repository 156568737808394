<template>
  <v-app-bar app :dense="toolbarDense">
    <v-app-bar-nav-icon @click.stop="toggleNavbar" class="custom-nav-icon">
      <v-icon>{{ toggleNavbarIcon }}</v-icon>
    </v-app-bar-nav-icon>

    <div v-if="!navbarShow" @click="routeToHomePage">
      <v-toolbar-title class="text-center">
        <v-avatar tile class="company-logo">
          <img
            width="32px"
            height="auto"
            src="../../assets/trackunit-logo-white.svg"
            alt="Trackunit Field Install Tool"
          />
          
        </v-avatar>
        <span class="logo-name">Field Install Tool</span>
      </v-toolbar-title>
      <!--<v-toolbar-title> -->
      <!-- <span class="logo-name">Trackunit Field Install Tool</span> -->
      <!-- </v-toolbar-title> -->

      <!--<v-toolbar-title> -->
      <!-- <v-toolbar-title class="text-center"> -->
      <!--<v-toolbar-title> -->
      <!-- <v-avatar tile class="company-logo"> -->
      <!-- <span class="logo-name">Trackunit Field Install Tool</span> -->
      <!-- <span  class="text-right"> -->
      <!-- <v-btn class="justify-right" fab small color="pink" @click="openModal()"><v-icon color="white">mdi-help-circle</v-icon></v-btn> -->
      <!-- </span> -->
      <!-- <img src="../../assets/logo-trackunit.png" alt="Trackunit-logo" /> -->
      <!-- </v-avatar> -->
      <!-- </v-toolbar-title> -->
    </div>
    <Guide ref="modal" :isGuideMain="true"></Guide>
    
    <v-spacer />
    <v-btn
      v-if="isGuideComponent"
      fab
      @click="openModal()"
      class="rounded-btn btn-width mr-4"
      ><v-icon>mdi-help-circle</v-icon></v-btn
    >
    <profile />
  </v-app-bar>
</template>
<style scoped>
/* .theme--light.v-app-bar.v-toolbar.v-sheet{
    font-family: "roboto", sans-serif;
    background: linear-gradient(120deg, #556cdc, #128bfc, #18bef1);
} */
.btn-align {
  margin-right: 20px;
  font-size: 12px !important;
  text-transform: capitalize;
}
.plus-icon {
  font-size: 12px !important;
}
.input-Field {
  height: 40px;
  max-width: 15%;
}
.margin-right-10 {
  margin-right: 10px !important;
}
.add-ticket-request {
  /* padding: 10px; */
  font-size: 20px !important;
}
.iconbtn {
  border: unset !important;
  box-shadow: unset !important;
  background-color: transparent !important;
  padding: 0px !important;
  min-width: 30px !important;
}
.iconbtn::before {
  background-color: transparent !important;
}

.plus-button {
  color: var(--v-primary) !important;
  background: #f5f5f5 !important;
}
.v-btn--fab.v-size--small {
  height: 35px;
  width: 35px;
}
.rounded-btn {
  background: #f44336 !important;
  height: 30px !important;
  width: 30px !important;
}

.custom-nav-icon {
  color: #fff !important;
}
.company-logo {
  width: 32px !important;
  height: auto !important;
}
.logo-name{
  font-size: 1.3rem;
    line-height: 1.5;
    color: #FFF;
}
.guide-icon{
  /* justify-content: space-between !important; */
}
.btn-width{
  /* min-width:50%; */
  position: inherit;
  left: 14px;
}
</style>
<script>
import { mapGetters } from "vuex";
// import Breadcrumbs from "../widget/AppBreadcrumbs.vue";
import Profile from "../widget/AppProfile.vue";
// import Notification from "../widget/AppNotification.vue";
// import { hasAccess } from "../../config/admin.route.config";
import api from "../../api/api.service";
import config from "../../config";
import Guide from "@/components/Guide";
// import httpClient from "../../api/httpClient";
// import EventBus from "../../utils/EventBus";

export default {
  name: "TheLayoutToolbar",
  components: {
    // Breadcrumbs,
    Profile,
    Guide,
    // Notification
  },
  data: () => ({
    userRole: "",
    client: "",
    project: "",
    isClient: true,
    isProject: false,
    isAccess: {
      reportAbug: true,
      reportRequest: true,
    },
    clientList: [],
    projectList: [],
    accessNotes: false,
    fromEventBus: false,
    isGuideComponent: false,
    currentRoute: null,
  }),
  computed: {
    ...mapGetters([
      "toolbarDense",
      "navbarShow",
      "roles",
      "clientId",
      // "themeShow",
      "projectId",
      "isProjectChanged",
    ]),
    toggleNavbarIcon() {
      return this.navbarShow
        ? "mdi-format-indent-decrease"
        : "mdi-format-indent-increase";
    },
    isTrackunitClientSelected() {
      if (this.client == config.TRACKUNIT_CLIENT_ID) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.handleRouter();
    // EventBus.$on('set-clientId', () => {
    //   this.client = this.clientId
    //   if (this.clientId) {
    //     this.fromEventBus = true;
    //     this.loadProjects();
    //   }
    // });
    // EventBus.$on('set-projectId', () => {
    //   this.project = this.projectId
    // });
    // EventBus.$on('reload-projects', () => {
    //   this.client = this.clientId
    //   if (this.clientId) {
    //     this.$store.dispatch("isProjectChanged", false);
    //     this.loadProjects();
    //   }
    // });
    // EventBus.$on('reload-clients', () => {
    //   this.client = this.clientId
    //   if (!this.clientId) {
    //     this.loadClients();
    //     this.$store.dispatch("projectId", "");
    //   }
    // });
    // this.loadClients();
    // if (this.clientId) {
    //   this.loadProjects();
    // } else {
    //   this.$store.dispatch("projectId", "");
    // }
    // this.isAccess = hasAccess.discovery[this.roles]
    //   ? hasAccess.discovery[this.roles]
    //   : this.isAccess;
    // this.roles == "Technical Expert" ||
    // this.roles == "Project Manager" ||
    // this.roles == "Admin" ||
    // this.roles == "Super Admin"
    //   ? (this.isClient = false)
    //   : (this.isClient = true);
    // // this.roles == "Developers" || this.roles == "Admin" || this.roles == 'Super Admin' ? this.isProject = false : this.isProject= true;
    // this.accessNotes = hasAccess.accessNotes.includes(this.roles);
  },
  watch: {
    deep: true,
    immediate: true,
    $route: function () {
      this.handleRouter();
    },
  },
  methods: {
    updateClient() {
      this.$store.dispatch("ClientId", this.client);
      this.$store.dispatch("projectId", "");
      this.$store.dispatch("isProjectChanged", false);

      if (
        this.client != config.TRACKUNIT_CLIENT_ID &&
        this.$route.path == "/report-management/newrequest"
      ) {
        this.$router.push({ name: "discovery" });
      }
      this.$router.go();
    },
    updateProject() {
      this.$store.dispatch("projectId", this.project);
      this.$store.dispatch("isProjectChanged", true);
      this.$router.go();

      // this.$store.dispatch('projectId', this.project);
    },
    toggleNavbar() {
      this.$store.dispatch("NavbarToggle");
    },
    async loadClients() {
      try {
        this.clientList = await api.getClients();
        let allClient = {
          clientId: "",
          clientName: "All",
        };
        this.clientList.unshift(allClient);
        this.client = this.clientId;
      } catch (e) {
        this.$toast.error("Unable to load the cilents" + e);
      }
    },
    // async getClientDetails() {
    //   let clientId = this.$store.getters.clientId;
    //   try {
    //     let client = await httpClient.getClientDetailsById(clientId);

    //     if (client.projects.length > 0) {
    //       client.projects.map(projectItem => {
    //         if (projectItem.defaultProject) {
    //           //set the default project for the client selected
    //           this.project = projectItem.projectId;

    //           //update the projectID to the store
    //           this.$store.dispatch("projectId", projectItem.projectId);
    //         }
    //       });
    //     }
    //   } catch (e) {
    //     console.log(e);
    //   }
    // },
    async loadProjects() {
      if (this.clientId) {
        try {
          let client = await api.getClientById(this.clientId);
          this.projectList = client.projects;
          let allProject = {
            projectId: "",
            projectName: "All",
          };
          this.projectList.unshift(allProject);
          this.project = this.projectId;

          //console.log("Store isProjectChanged: ", this.isProjectChanged);
          if (!this.isProjectChanged && !this.fromEventBus) {
            this.$store.dispatch("isProjectChanged", false);
            this.getClientDetails();
          }
        } catch (e) {
          this.$toast.error("Unable to load the projects" + e);
        }
      }
    },
    createNewClient() {
      this.$router.push("/new-client").catch(() => {});
    },
    createNewProject() {
      if (this.client) {
        this.$router.push("/project-management/add").catch(() => {});
      } else {
        this.$toast.error("Please select a Client");
      }
    },
    clickHandle() {
      if (this.client && this.project) {
        this.$router.push("/report-a-bug").catch(() => {});
      } else if (this.client == "" && this.project == "") {
        this.$toast.error("Please select a Client and Project");
      } else if (this.client != "" && this.project == "") {
        this.$toast.error("Please select a Project");
      } else if (this.client == "" && this.project != "") {
        this.$toast.error("Please select a Client");
      }
    },
    clickHandleFeature() {
      if (this.client && this.project) {
        this.$router.push("/feature-request").catch(() => {});
      } else if (this.client == "" && this.project == "") {
        this.$toast.error("Please select a Client and Project");
      } else if (this.client != "" && this.project == "") {
        this.$toast.error("Please select a Project");
      } else if (this.client == "" && this.project != "") {
        this.$toast.error("Please select a Client");
      }
    },
    clickHandleNewReport() {
      if (this.client) {
        this.$router.push({ name: "newreport-request" }).catch(() => {});
      } else {
        this.$toast.error("Please select a Client");
      }
    },
    clickHandleTask() {
      this.$router.push({ name: "create-task" }).catch(() => {});
    },
    openModal() {
      window.dataLayer.push({
          'event': "page_view_event",
          "pageTitle": "Guides"
        })
      this.$refs.modal.showModal();
    },
    handleRouter() {
      this.currentRoute = this.$route.fullPath;
      if (this.currentRoute == "/guide") {
        this.isGuideComponent = false;
      } else {
        this.isGuideComponent = true;
      }
    },
    routeToHomePage() {
      this.$router.push({ path: "/" }).catch(() => {});
    }
  },
};
</script>
