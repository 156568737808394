//roles:["Admin","Developers","Support Admin","Support Manager","Sales"],

export const adminRoutes = [
  {
    path: "/",
    children: [
      {
        path: "/",
        alias: "/",
        name: "Home",
        meta: {
          title: "Home",
          icon: "mdi-home-outline",
        },
      },
    ],
  },
  {
    path: "/Safety-DeploymentInfo",
    alias: "/",
    name: "Discovery",
    meta: {
      title: "Discovery",
      icon: "mdi-toy-brick-search-outline",
    },
  },
  {
    path: "/activity",
    alias: "/",
    name: "Activity",
    meta: {
      title: "Activity",
      icon: "mdi-progress-check",
    },
  },
  {
    path: "/support",
    alias: "/",
    name: "Support",
    meta: {
      title: "Support",
      icon: "mdi-help-circle-outline",
    },
  },
  {
    path: "/guide",
    alias: "/",
    name: "Guides",
    meta: {
      title: "Guides",
      icon: "mdi-television-guide",
    },
  },
  // {
  //   path: "/statusandreporting",
  //   alias: "/",
  //   name: "Status and Reporting",
  //   meta: {
  //     title: "Status and Reporting",
  //     icon: "mdi-checkbox-marked-outline",
  //   },
  // },
  // {
  //   path: "/equipmentrecords",
  //   alias: "/",
  //   name: "Equipment Records",
  //   meta: {
  //     title: "Equipment Records",
  //     icon: "mdi-map-marker-path",
  //   },
  // },
  // {
  //   path: "/servicerecords",
  //   alias: "/",
  //   name: "Service Records",
  //   meta: {
  //     title: "Service Records",
  //     icon: "mdi-map-marker-path",
  //   },
  // },
  // {
  //   path: "/logout",
  //   alias: "/logout",
  //   name: "Logout",
  //   meta: {
  //     title: "Logout",
  //     icon: "mdi-exit-to-app",
  //   },
  // },
];
export const hasAccess = {
  discovery: {
    Sales: {
      environment: false,
      customerReports: true,
      internalReports: true,
      reportStats: true,
      reportAbug: true,
      reportRequest: true
    },
    "Support Manager": {
      customerReports: false,
      internalReports: true,
      ticketStats: true,
      reportStats: false,
      reportAbug: true,
      reportRequest: false
    }
  },
  viewReport: {
    "Support Admin": {
      updateStatus: false,
      editReport: true
    },
    Sales: {
      updateStatus: false,
      editReport: true
    }
  },
  accessNotes: ["Admin", "Developers"]
};
