// import axios from "axios";
// import env from "../config";
import axios from "../api/axios";
// const client = axios.create({
//   baseURL: env.API_ENDPOINT,
//   // baseURL: `http://35.236.93.48/api/Client`,
//   json: true
// });

export default {
  async execute(method, resource, data) {
    return axios.fitEndPoint({
      method,
      url: resource,
      data
    }).then(req => {
      return req.data;
    });
  },
  getDiscoveryByLocation(latitude, longitude, zipCode, address) {
    return this.execute("get", `Discovery/location?latitude=${latitude}&longittude=${longitude}&zipCode=${zipCode}&address=${address}`)
  },
  getDiscoveryByWork(companyId, branch, city, asset) {
    return this.execute("get", `/Discovery/work?companyId=${companyId}&branch=${branch}&city=${city}&asset=${asset}`)
  },
  getAllServices(){
    return this.execute("get", `/Discovery/ServiceTypes`);
  },
  getAllBrands(){
    return this.execute("get", `/Discovery/Manufacturers`);
  },
  getAllBranches(companyId){
    return this.execute("get", `/Branch/${companyId}`);
  },
  getAllCategories(){
    return this.execute("get", `/Discovery/MachineTypes`);
  },
  getMachineTypesByCompany(companyId){
    return this.execute("get", `/Discovery/RentalStatus?companyId=${companyId}`);
  },
  getAllSearchData(assetVal,serviceType,serviceStatus,machineStatus,brandType,pageNumber,companyId,email,cityName,branchId,userEmailAddress,searchType){
    return this.execute("get", `/Discovery/SearchByWork?searchText=${assetVal}&serviceType=${serviceType}&serviceStatus=${serviceStatus}&rentalStatus=${machineStatus}&manufacturer=${brandType}&pageNumber=${pageNumber}&companyId=${companyId}&VendorTech=${email}&cityName=${cityName}&branchId=${branchId}&userEmailAddress=${userEmailAddress}&searchType=${searchType}`);
  },
  // Discovery/Asset/1
  getSearchDataById(serviceId){
    return this.execute("get", `/Discovery/Service/${serviceId}`);
  },
  capturePicture(payload) {
    return this.execute("post", `/Discovery/ServiceUpdate`, payload);
  },
  capturePictureSyncFunctionality(payload) {
    return this.execute("put", `/Discovery/ServiceImageUpload`, payload);
  },
  // complete installation
  completeStatus(serviceId, payload) {
    return this.execute("put", `/Discovery/CompleteStatus/${serviceId}`, payload);
  },
  getBeginInstall(serviceId){
    return this.execute("get", `/Discovery/ServicePhotos?serviceId=${serviceId}`);
  },
  saveVerified(serviceId,payload){
    return this.execute("post", `/Discovery/Save/${serviceId}`,payload);
  },
  saveAndValidateVerified(serviceId,payload){
    return this.execute("post", `/Discovery/SaveValidate/${serviceId}`,payload);
  },
  getServiceVerificationDetails(serviceId){
    return this.execute("get", `/Discovery/servicevalidate?serviceId=${serviceId}`);
  },
  getMarkersList(companyId){
    return this.execute("get", `/Discovery/servicecitylist?companyId=${companyId}`);
  },
  // Unit 360 
  getServiceTypeList(){
    return this.execute("GET", `/Discovery/servicetypes`);
  },
  getAuditList(
    search,
    SelectedCustomer,
    fromDate,
    toDate,
    serviceType,
    serviceStatus,
    userId,
    pageNumber
  ) {
    return this.execute(
      "get",
      `/Discovery/Report/search?query=${search}&customerId=${SelectedCustomer}&fromDate=${fromDate}&toDate=${toDate}&serviceType=${serviceType}&serviceStatus=${serviceStatus}&userId=${userId}&pageNumber=${pageNumber}`
    );
  },
  getAuditDataById(serviceId,userId){
    return this.execute("get", `/Discovery/Report/${serviceId}?userId="${userId}`);
  },
  getPDFLinks(keywords){
    return this.execute("get", `/Discovery/Guides/${keywords}`);
  },
  createTicket(payload){
    return this.execute("post", `/Discovery/Support/CreateTicket`,payload);
  },
  getExampleData(machienId,companyId,serviceType){
    return this.execute("get", `/Discovery/validatecompleteservice?machienId=${machienId}&companyId=${companyId}&serviceType=${serviceType}`);
  },
  saveTUSerial(serviceId,tuSerialNumber){
    return this.execute("post", `/Discovery/TUSerialUpdate?serviceId=${serviceId}&tuSerial=${tuSerialNumber}`);
  },
  getCompanyInfoData(companyId) {
    return this.execute("get", `Discovery/company/${companyId}/settings`);
  },
  getLargeVerificationStatus(verificationKey) {
    return this.execute("get", `/InstallVerification/${verificationKey}`);
  },
  getLargeVerificationLatestStatusKey(serviceId) {
    return this.execute("get", `/InstallVerification/StatusKey/${serviceId}`);
  },
  startProcess(payload) {
    return this.execute("post", `/InstallVerification/Start`,payload);
  },
  stopProcess(payload) {
    return this.execute("put", `/InstallVerification/Stop`,payload);
  },
  sendEmail(companyId,payload){
    return this.execute("post", `/Discovery/AvailableServicesEmailNotification?companyId=${companyId}`,payload);
  },
  getContractorCustomers(contractorId) {
    return this.execute("get", `/Contractor/${contractorId}/customers`);
  },
  getBranchList(companyId){
    return this.execute("get", `/Branch/Select/${companyId}`);
  },
  getBranchData(branchId,companyId){
    return this.execute("get", `/Branch/Detail/${branchId}?companyId=${companyId}`);
  },
  getBranchServiceCount(companyId,branchId){
    return this.execute("get", `/Branch/Select/${companyId}/${branchId}`)
  },
  branchCheck(payload){
    return this.execute("post", `/Branch/Check`, payload);
  },
  escalateAndBranchCheck(payload){
    return this.execute("post", `/Discovery/Branch/Check`, payload);
  },
  getLargeVerificationProceedNotes(serviceId) {
    return this.execute("get", `/InstallVerification/Details/${serviceId}`);
  },
  proceedConfirmation(serviceId, payload) {
    return this.execute("put", `/InstallVerification/Details/${serviceId}`, payload);
  },
  //equipment & service records
  getMasterBrandsList(){
    return this.execute("get", `/MasterData/Brands`);
  },
  getMasterMachinesList(){
    return this.execute("get", `/MasterData/MachineTypes`);
  },
    //Filed Tool Management Asset Management
    getAssetManagementList(companyId, duplicateMachine, withoutService, otherMachine, otherBrand,pageNumber,pageSize,searchValue,brand,machineType,sortBy,sortAsc,contractor){
      return this.execute("get", `/AssetTool/Assets?companyId=${companyId}&duplicateMachine=${duplicateMachine}&withoutService=${withoutService}&otherMachine=${otherMachine}&otherBrand=${otherBrand}&pageNumber=${pageNumber}&pageSize=${pageSize}&filterSerial=${searchValue}&brand=${brand}&machineType=${machineType}&sortColumn=${sortBy}&sortAsc=${sortAsc}&contractor=${contractor}`);
    },
    getMasterServicesList(){
      return this.execute("get", `/ServiceTypes`);
    },
    getAssetRecordById(Id){
      return this.execute("get", `/AssetTool/Assets/${Id}`);
    },
    updateAssetManagementRecord(Id,payload){
      return this.execute("put", `/AssetTool/Assets/${Id}`,payload);
    },
    createNewEquipmentRecord(payload){
      return this.execute("post", `/AssetTool/Assets`, payload);
    },
     // Service management Tool
  //load main table 
  getServiceManagementList(companyId, withoutService,pageNumber,pageSize,searchValue,serviceType,serviceStatus,sortBy,sortAsc,contractor){
    return this.execute("get", `/ServiceTool/Services?companyId=${companyId}&withoutService=${withoutService}&pageNumber=${pageNumber}&pageSize=${pageSize}&filterSerial=${searchValue}&serviceType=${serviceType}&serviceStatus=${serviceStatus}&sortColumn=${sortBy}&sortAsc=${sortAsc}&contractor=${contractor}`);
  },
  // edit inline 
  getServiceRecordById(serviceId){
    return this.execute("get", `/ServiceTool/Services/${serviceId}`);
  },
  // update inline 
  updateServiceManagementRecord(Id,payload){
  return this.execute("put", `/ServiceTool/Services/${Id}`,payload);
  },
  checkMachineExisted(machineValue,companyId,contractorId){
    return this.execute("get", `/AssetTool/AssetFK?searchText=${machineValue}&companyId=${companyId}&contractorId=${contractorId}`);
  },
  createNewServiceRecord(payload){
    return this.execute("post", `/ServiceTool/Services`, payload);
  },
  //Add New Service Related API's
  getServicePictures(serviceId){
    return this.execute("get", `/ServiceTool/ServicePhotos?serviceId=${serviceId}`);
  },
  addUpdateServicePictures(payload) {
    return this.execute("post", `/ServiceTool/ServicePhotosUpdate`, payload);
  },
  getCompanyDataById(id) {
    return this.execute("get", `/Company/${id}`);
  },
  getCompanyDataAll() {
    return this.execute("get", `/Company/Basic/All`);
  },
  getActivityList(contractorId,customerIds,vendorTechnician) {
    return this.execute("get", `/ActivityMatrix?contractorId=${contractorId}&companyId=${customerIds}&vendorTechnician=${vendorTechnician}`);
  },
  beginProcess(serviceId,payload) {
    return this.execute("put", `/Discovery/BeginInstallUpdate/${serviceId}`,payload);
  },
  resetServiceRecord(serviceId, payload) {
    return this.execute("put", `/ServiceTool/Reset/${serviceId}`, payload);
  },
  read3gSerialNumber(assetId) {
    return this.execute("get", `/Discovery/DefitTuSerial/${assetId}`);
  },
  setServiceSyncStatusToCompleted(serviceId) {
    return this.execute("put", `/Discovery/SyncStatus/${serviceId}`);
  },
  // install check
  getInstallCheckAppSheet(tuSerial) {
    return this.execute("get", `/InstallCheck/Appsheet?tuSerial=${tuSerial }`);
  },
  getInstallCheckUnit(tuSerial, customerId, contractorId, machineId) {
    return this.execute("get", `/InstallCheck/Unit?tuSerial=${tuSerial}&customerId=${customerId}&contractorId=${contractorId}&machineId=${machineId}`);
  },
  saveNruFlowOption(serviceId,payload){
    return this.execute("put", `/Discovery/CanUnitBeRepaired/${serviceId}`,payload);
  },
  resetAndSaveNruFlowOption(serviceId,payload) {
    return this.execute("put", `/Discovery/Reset/NruInspection/${serviceId}`,payload);
  },
  getNruFlowOptionDetails(serviceId){
    return this.execute("get", `/Discovery/CanUnitBeRepaired?serviceId=${serviceId}`);
  },
  createNruRepairFlow(payload) {
    return this.execute("post", `/Discovery/NruRepairService`, payload);
  },
  completedNruRepairFlow(payload) {
    return this.execute("post", `/Discovery/CompletedNruRepair`, payload);
  },
  getCheckInCheckOutInformation(userEmail) {
    return this.execute("get", `/Discovery/Branch/CheckInVerify?userEmail=${userEmail}`);
  },
  checkIn(payload) {
    return this.execute("post", `/Discovery/Branch/CheckIn`,payload);
  },
  checkOut(payload) {
    return this.execute("put", `/Discovery/Branch/CheckOut`, payload);
  },
  getEscalationInformation(branchId, companyId) {
    return this.execute("get", `/Discovery/branch/${branchId}/escalationstatus?companyId=${companyId}`);
  },
  escalateEquipmentAvailability(branchId, payload) {
    return this.execute("post", `/Discovery/Branch/${branchId}/escalate`,payload);
  },
  getAddedServicesInfo(payload) {
    return this.execute("post", `/Discovery/services/Details`, payload);
  },
  checkMachineCanEligible(payload){
    return this.execute("post", `/MachineMeta/CanEligible`,payload)
  },
  getServiceNRUCategories() {
    return this.execute("get", "ServiceNRUCategory/ServiceNRUCategories");
  }
};
