import { render, staticRenderFns } from "./InfoDialog.html?vue&type=template&id=051f8013&scoped=true&external"
import script from "./InfoDialog.js?vue&type=script&lang=js&external"
export * from "./InfoDialog.js?vue&type=script&lang=js&external"
import style0 from "./InfoDialog.scss?vue&type=style&index=0&id=051f8013&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "051f8013",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardText,VCardTitle,VCol,VDialog,VIcon,VRow})
