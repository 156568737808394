<template>
  <v-menu
    offset-y
    origin="center center"
    :nudge-bottom="10"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <v-list class="profile-list">
        <v-list-item
          v-on="on"
          :class="{
            'profile-section': navbarShow,
            'profile-section-mobile': !navbarShow,
          }"
        >
          <v-list-item-avatar class="ml-auto">
            <!-- <img
              src="../../assets/profile-icon.svg"
              class="mdi-icon-style-2"
            /> -->
            <v-icon class="profile-avatar">mdi-account-outline</v-icon>
          </v-list-item-avatar>
          <v-list-item-content v-if="navbarShow">
            <v-list-item-title> </v-list-item-title>
            <v-list-item-subtitle>
              <p class="mb-0 username-label">
                {{ name }}
                <!-- Admin User -->
              </p>
              <p class="mb-0 company-label">
                {{ user }}
                <!-- admin@incus5.com -->
              </p>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
    <v-list class="pa-0">
      <v-list-item
        v-for="(item, index) in menuitems"
        :key="index"
        ripple="ripple"
        :disabled="item.disabled"
        :target="item.target"
        rel="noopener"
        @click="item.click"
      >
        <v-list-item-action v-if="item.icon == 'mdi-account'">
          <v-avatar size="26px">
            <img v-if="avatar.imageURL" :src="avatar.imageURL" alt="name" />
            <img
              v-else
              src="../../assets/profile-icon.svg"
              class="mdi-icon-style-2"
            />
          </v-avatar>
        </v-list-item-action>
        <v-list-item-action v-else>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="menu-title">
            <span v-if="item.title != 'Logout'">{{ item.title }}</span>
            <span v-else class="title-logout">{{ item.title }}</span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <!-- menuitems_Cus && Vendor -->
    <!-- <v-list class="pa-0" v-else>
      <v-list-item
        v-for="(item, index) in menuitems_Cus_Vendor"
        :key="index"
        ripple="ripple"
        :disabled="item.disabled"
        :target="item.target"
        rel="noopener"
        @click="item.click"
      >
        <v-list-item-action v-if="item.icon == 'mdi-account'">
          <v-avatar size="26px">
            <img v-if="avatar.imageURL" :src="avatar.imageURL" alt="name" />
            <img
              v-else
              src="../../assets/profile-icon.svg"
              class="mdi-icon-style-2"
            />
          </v-avatar>
        </v-list-item-action>
        <v-list-item-action v-else>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="menu-title">{{
            item.title
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list> -->
  </v-menu>
</template>
<style scoped lang="scss">
@import "../../assets/styles/variables.scss";
.profile-list {
  padding: 0 !important;
  background: transparent !important;
  color: #fff !important;
}
.mdi-icon-style {
  font-size: 22px !important;
}
.mdi-icon-style-2 {
  height: 20px;
  position: fixed !important;
}
.profile-section {
  // padding: 0px;
  width: 210px;
}
.profile-section-mobile {
  width: 60px;
}
.v-menu__content {
  top: 65px !important;
  // border-radius: 0;
}
.v-list-item__avatar {
  margin-right: 5px !important;
}
.v-list-item__action {
  margin-right: 10px !important;
}
.username-label {
  color: $black-shade-4;
  font-weight: 500;
}
.company-label {
  color: $neutral-gray-7;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.menu-title {
  height: 24px;
}
.title-logout {
  color: blue !important;
}
.profile-avatar {
  color: #fff !important;
  font-size: 24px;
}
</style>
<script>
import { mapGetters } from "vuex";
import apiService from "../../api/api.service";
export default {
  name: "AppProfile",
  data() {
    return {
      result: [],
      isAvatar: false,
      isProfile: false,
      avatar: {
        imageURL: null,
      },
      menuitems: [
        //       {
        //         icon: "mdi-account",
        //         title: "Profile",
        //         click: () => {
        //           this.$router
        //             .push({
        //               path: "/user-profile"
        //             })
        //             .catch(() => {});
        //         }
        //       },

        // {
        //         icon: "mdi-bug",
        //         title: "Support",
        //         click: () => {
        //           this.$router
        //             .push({
        //               path: "/support-tickets"
        //             })
        //             .catch(() => {});
        //         }
        //       },

        //       {
        //         icon: "mdi-lock-outline",
        //         title: "Change Password",
        //         click: () => {
        //           this.$router
        //             .push({
        //               path: "/change-password"
        //             })
        //             .catch(() => {});
        //         }
        //       },
        {
          icon: "mdi-account",
          title: this.$store.getters.name,
          click: () => {},
        },
        {
          icon: "mdi-email",
          title: this.$store.getters.user,
          click: () => {},
        },
        // {
        //   icon: "mdi-cog",
        //   title: "Sync Settings",
        //   click: () => {
        //     if (this.$route.path !== "/sync-settings") {
        //       this.$router
        //       .push({
        //         path: "/sync-settings",
        //       })
        //       .catch(() => {});
        //     }
        //   },
        // },
        {
          icon: "mdi-exit-to-app",
          href: "#",
          title: "Logout",
          click: () => {
            this.logout();
          },
        },
      ],
      menuitems_Cus_Vendor: [
        {
          icon: "mdi-account",
          title: "Profile",
          click: () => {
            this.$router
              .push({
                path: "/user-profile",
              })
              .catch(() => {});
          },
        },

        {
          icon: "mdi-lock-outline",
          title: "Change Password",
          click: () => {
            this.$router
              .push({
                path: "/change-password",
              })
              .catch(() => {});
          },
        },
        {
          icon: "mdi-exit-to-app",
          href: "#",
          title: "Logout",
          click: () => {
            this.logout();
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "user",
      "name",
      // 'avatar',
      "status",
      "roles",
      "navbarShow",
    ]),
  },
  mounted() {
    this.userId = this.$store.getters.userId;
    // this.getProfileById();
  },
  methods: {
    logout() {
      this.$store.dispatch("LogOut");
      this.$router.push("/signin");
    },
    async getProfileById() {
      this.isProfile = true;
      try {
        this.result = await apiService.getProfile(this.userId);
        if (this.result.image) {
          this.avatar.imageURL = this.result.image;
        }
        this.isProfile = false;
      } catch (err) {
        this.isProfile = false;
        this.$toast.error("Unable to load the report requests" + err);
      }
    },
  },
};
</script>
