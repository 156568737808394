const packageJson = require('../../package.json');

const env = process.env.NODE_ENV || 'development';
console.log("environment", env);
const config = require("../../secrets/config");
module.exports = {
  title: packageJson.title,
  version: packageJson.version,
  settings: {
    dense: false,
    footer: false,
    navbar: {
      show: false,
      dense: false,
      logo: true,
    }
  },
  Environment:config.Environment,
  API_ENDPOINT: config.API_ENDPOINT,
  FIT_API_ENDPOINT: config.FIT_API_ENDPOINT,
  USER_ENDPOINT: config.USER_ENDPOINT,
  COMPANY_ENDPOINT: config.COMPANY_ENDPOINT,
  AUTH_TOKEN_INTERVAL: config.AUTH_TOKEN_INTERVAL,
  VUE_APP_OKTA_OAUTH2_CLIENT_ID_SPA: config.VUE_APP_OKTA_OAUTH2_CLIENT_ID_SPA,
  VUE_APP_OKTA_CLIENT_ORGURL: config.VUE_APP_OKTA_CLIENT_ORGURL,
  BARCODE_LICENSE: config.BARCODE_LICENSE,
  BARCODE_LICENSE_PATH: config.BARCODE_LICENSE_PATH,
  // GOOGLE_TAG_MANAGER_ID: config.GOOGLE_TAG_MANAGER_ID,
  // GOOGLE_ANALYTICS_ID: config.GOOGLE_ANALYTICS_ID
}