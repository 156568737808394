// import GuideMain from "@/components/GuideMain";
import utils from "../../utils/util";
import { mapGetters } from "vuex";
import pageheader from "../pageheader";
import fitapi from "@/api/fit.service";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
// export default {
export default {
  name: 'guide',
  components: {pageheader, VuePdfEmbed},
  props: ["isGuideMain","isShowHeader","isLabel"],
  data () {
    return {
      input:{},
      search:"",
      dialog:false,
      currentPage: 0,
			pageCount: 0,
      src: null,
			numPages: undefined,
      showDialog:false,
      pdfList:[],
      isSearch:false,
      errorMessage:"",
      ispdfList:false,
      loadPDF:false
    }
  },
  computed: {
    ...mapGetters(["roles","deviceFK","serviceType", "searchMachineId","companyName", "brand", "validServiceType", "model", "machineType"])
  },
  filters: {
    companyNameDisplay: function(value) {
      if (value) {
        return value.length > 21 ? value.substring(0,21) + "..." : value;
      }
    },
  },
  mounted () {
    window.dataLayer = window.dataLayer || [];
    if (!this.isGuideMain)  {
      this.sendPageTitle();
    }
    this.input = utils.getInputContent("guide", this.roles);
  },
  methods: {
    showModal() {
      this.showDialog = true;
      this.search = "";
      if(this.validServiceType){
        this.search = this.validServiceType;
      }
      if (this.companyName) {
        this.search = this.search ? this.search + "," + this.companyName : this.companyName;
      }
      if (this.brand) {
        this.search = this.search ? this.search + "," + this.brand : this.brand;
      }
      if(this.deviceFK){
        this.search = this.search ? this.search + "," + this.deviceFK : this.deviceFK;
      }
      if(this.model){
        this.search = this.search ? this.search + "," + this.model : this.model;
      }
      if(this.machineType){
        this.search = this.search ? this.search + "," + this.machineType : this.machineType;
      }
      if(this.search != ""){
        this.getPDFList();
      }
      },
      async getPDFList(){
        window.dataLayer.push({
          'event': "guides_search",
          "guidesSearchText": this.search
        })
        this.isSearch = true;
        this.ispdfList = true;
        try{
          let response = await fitapi.getPDFLinks(this.search);
          if(response.data){
            this.ispdfList = false;
            this.isSearch = false;
            this.pdfList = [];
            this.pdfList = response.data;
            if(this.pdfList.length<=0){
            this.errorMessage = "No Guides Found...";
            }
          }else{
            this.ispdfList = false;
            this.isSearch = false;
            this.pdfList = [];
            this.errorMessage = "No Guides Found...";
          }
        }catch(err){
          this.ispdfList = false;
          this.isSearch = false;
          console.log(err);
        }
      },
      openPDFFile(url){
        this.src = "";
        this.dialog = true;
        this.src = url;
        // var loadingTask = pdf.createLoadingTask(url);
        // this.src = loadingTask;
        // this.src.promise.then(pdf => {
        //   this.numPages = pdf.numPages;
        // });
      },
      progress(p) {
        if (p && p.loaded==p.total) {
          this.loadPDF = false
        } else {
          this.loadPDF = true;
        }
      },
      closePDF(){
        this.dialog = false;
        this.src = "";
        this.numPages = "";
        this.loadPDF = false;
      },
      sendPageTitle() {
        window.dataLayer.push({
          'event': "page_view_event",
          "pageTitle": "Guides"
        })
      }
  }
}


