import login from "@/api/login";
import userService from "../../api/user.service";
import fitService from "../../api/fit.service";
// import { v1 as uuidv4 } from "uuid";

//simple way to manage minimum EquipmentAvailability per customer
const CustomersMinEquipmentAvailabilityMap = {
  'f346c05f-d357-11eb-a698-42010ad6d036':8,  //Herc
  'cd1ac16e-0210-40e1-bb86-374be35147ac':6,   //H&E
  'default':6
} 

const GetCustomerMinEquipmentAvailability = (id) => id in CustomersMinEquipmentAvailabilityMap ? CustomersMinEquipmentAvailabilityMap[id]
                                                                                              : CustomersMinEquipmentAvailabilityMap['default'];

const user = {
  state: {
    token: "",
    refreshToken: "",
    tokenExpiryTime: "",
    roles: "",
    user: "",
    name: "",
    userId: "",
    customerId: "",
    customerIds: "",
    toolIds: "",
    referenceId: "",
    reportvalue: "",
    auditFinanceCustomer: "",
    auditFinanceFromDate: "",
    auditFinanceToDate: "",
    FinanceCustomerName: "",
    ToolsComName: "",
    ToolsCustomerId: "",
    ToolsapiToken: "",
    AppSheetToolsComName: "",
    AppSheetToolsCustomerId: "",
    AppSheetToolsapiToken: "",
    reportId: "",
    companyName: "",
    companyAppsheetId: "",
    supportTicketsReferenceId: "",
    assetWizardValue: "",
    serviceWizardValue: "",
    assetRefreshVal: "",
    serviceRefreshVal: "",
    activeTab: "",
    assetBatchId: "",
    serviceBatchId: "",
    setErrorMessageLogin: "",
    loggedInReferenceId: "",
    searchMasterData: [],
    searchMachineId: "",
    assetId: "",
    tuSerialNumber: "",
    serviceTypeActual: "",
    serviceDescription: "",
    brand: "",
    machineType: "",
    model: "",
    serviceId: "",
    verificationType: "",
    engineType: "",
    pageSize: 1,
    servicePhotosList: [],
    DefitServicePhotosList: [],
    CRId: "",
    cityName: "",
    auditPageSize: "",
    deviceFK: "",
    serviceType: "",
    serviceLastUpdated: "",
    vendorName: "",
    validServiceType: "",
    branchDetails:{},
    branchId: "",
    branchName: "",
    branchGuId: "",
    contactedThroughPhone: false,
    contactedThroughEmail: false,
    verificationKey: "",
    activityCompanyId: "",
    sortBy: "",
    sortDesc: [true],
    pageNumber:1,
    nruInspFlowType: "",
    machineYear: "",
    minEquipmentAvailability:6,
    suggestedRefitInstType:"",
    validRefitInstTypes:[],
    machineCanEligibleStatus: ""
    
  },
  getters: {
    token: (state) => state.token,
    refreshToken: (state) => state.refreshToken,
    tokenExpiryTime: (state) => state.tokenExpiryTime,
    roles: (state) => state.roles,
    name: (state) => state.name,
    user: (state) => state.user,
    userId: (state) => state.userId,
    customerId: (state) => state.customerId,
    customerIds: (state) => state.customerIds,
    toolIds: (state) => state.toolIds,
    referenceId: (state) => state.referenceId,
    avatar: () => null,
    reportvalue: (state) => state.reportvalue,
    auditFinanceCustomer: (state) => state.auditFinanceCustomer,
    auditFinanceFromDate: (state) => state.auditFinanceFromDate,
    auditFinanceToDate: (state) => state.auditFinanceToDate,
    FinanceCustomerName: (state) => state.FinanceCustomerName,
    ToolsComName: (state) => state.ToolsComName,
    ToolsCustomerId: (state) => state.ToolsCustomerId,
    ToolsapiToken: (state) => state.ToolsapiToken,
    AppSheetToolsComName: (state) => state.AppSheetToolsComName,
    AppSheetToolsCustomerId: (state) => state.AppSheetToolsCustomerId,
    AppSheetToolsapiToken: (state) => state.AppSheetToolsapiToken,
    reportId: (state) => state.reportId,
    companyName: (state) => state.companyName,
    companyAppsheetId: (state) => state.companyAppsheetId,
    supportTicketsReferenceId: (state) => state.supportTicketsReferenceId,
    assetWizardValue: (state) => state.assetWizardValue,
    serviceWizardValue: (state) => state.serviceWizardValue,
    assetRefreshVal: (state) => state.assetRefreshVal,
    serviceRefreshVal: (state) => state.serviceRefreshVal,
    activeTab: (state) => state.activeTab,
    assetBatchId: (state) => state.assetBatchId,
    serviceBatchId: (state) => state.serviceBatchId,
    setErrorMessageLogin: (state) => state.setErrorMessageLogin,
    loggedInReferenceId: (state) => state.loggedInReferenceId,
    searchMasterData: (state) => state.searchMasterData,
    searchMachineId: (state) => state.searchMachineId,
    assetId: (state) => state.assetId,
    tuSerialNumber: (state) => state.tuSerialNumber,
    serviceTypeActual: (state) => state.serviceTypeActual,
    serviceDescription: (state) => state.serviceDescription, 
    brand: (state) => state.brand,
    machineType: (state) => state.machineType,
    model: (state) => state.model,
    serviceId: (state) => state.serviceId,  
    verificationType: (state) => state.verificationType,   
    engineType: (state) => state.engineType,  
    pageSize: (state) => state.pageSize,
    servicePhotosList: (state) => state.servicePhotosList,
    DefitServicePhotosList: (state) => state.DefitServicePhotosList,
    CRId: (state) => state.CRId,
    cityName: (state) => state.cityName,
    auditPageSize: (state) => state.auditPageSize,
    deviceFK: (state) => state.deviceFK,
    serviceType: (state) => state.serviceType,
    serviceLastUpdated: (state) => state.serviceLastUpdated,
    vendorName: (state) => state.vendorName,
    validServiceType: (state) => state.validServiceType,
    branchDetails: (state) => state.branchDetails,
    branchId: (state) => state.branchId,
    branchName: (state) => state.branchId,
    branchGuId: (state) => state.branchGuId,
    contactedThroughPhone: (state) => state.contactedThroughPhone,
    contactedThroughEmail: (state) => state.contactedThroughEmail,
    verificationKey: (state) => state.verificationKey,
    nruInspFlowType: (state) => state.nruInspFlowType,
    activityCompanyId: (state) => state.activityCompanyId,
    pageNumber: state => state.pageNumber,
    sortBy: state => state.sortBy,
    sortDesc: (state) => state.sortDesc, 
    machineYear: state => state.machineYear,
    minEquipmentAvailability: (state) => GetCustomerMinEquipmentAvailability(state.CRId),
    suggestedRefitInstType:state => state.suggestedRefitInstType,
    validRefitInstTypes:state => state.validRefitInstTypes,
    machineCanEligibleStatus: state => state.machineCanEligibleStatus
  },

  mutations: {
    SET_USER_INFO: (state, payload) => {
      if (payload.logout) {
        state.token = "";
        (state.refreshToken = ""),
          (state.tokenExpiryTime = ""),
          (state.roles = "");
        state.user = "";
        state.name = "";
        state.userId = "";
        state.customerId = "";
        state.customerIds = "";
        state.toolIds = "";
        state.referenceId = "";
        state.reportvalue = "";
        state.auditFinanceCustomer = "";
        state.auditFinanceFromDate = "";
        state.auditFinanceToDate = "";
        state.FinanceCustomerName = "";
        state.ToolsComName = "";
        state.ToolsCustomerId = "";
        state.ToolsapiToken = "";
        (state.AppSheetToolsComName = ""),
          (state.AppSheetToolsCustomerId = ""),
          (state.AppSheetToolsapiToken = ""),
          (state.reportId = ""),
          (state.companyName = "");
        state.companyAppsheetId = "";
        state.supportTicketsReferenceId = "";
        state.assetWizardValue = "";
        state.serviceWizardValue = "";
        state.assetRefreshVal = "";
        state.serviceRefreshVal = "";
        state.activeTab = "";
        state.assetBatchId = "";
        state.serviceBatchId = "";
        state.setErrorMessageLogin = "",
        state.loggedInReferenceId = "";
        state.searchMasterData = [];
        state.searchMachineId = "";
        state.assetId = "";
        state.tuSerialNumber = "";
        state.serviceTypeActual = "";
        state.serviceDescription = "";
        state.brand = "";
        state.machineType = "";
        state.model = "";
        state.serviceId = "";
        state.verificationType = "";
        state.engineType = "";
        state.pageSize = "";
        state.servicePhotosList = "";
        state.DefitServicePhotosList = "";
        state.CRId = "";
        state.cityName = "";
        state.auditPageSize = "";
        state.deviceFK = "";
        state.serviceType = "";
        state.serviceLastUpdated = "",
        state.vendorName = "";
        state.validServiceType = "";
        state.branchDetails = {};
        state.branchId = "";
        state.branchName = "";
        state.branchGuId = "";
        state.contactedThroughPhone = false;
        state.contactedThroughEmail = false;
        state.verificationKey = "";
        state.nruInspFlowType = "";
        state.activityCompanyId = "";
        state.pageNumber = "";
        state.sortBy = "";
        state.sortDesc = "";
        state.machineYear = "";
        state.machineCanEligibleStatus = ""
      } else {
        state.token = payload.token || state.token;
        // state.refreshToken = payload.refreshToken || state.refreshToken;
        // state.tokenExpiryTime = payload.tokenExpiryTime || state.tokenExpiryTime;
        state.roles = payload.userRole || state.userRole;
        state.user = payload.username || state.username;
        state.name = payload.flName || state.flName;
        state.userId = payload.userId || state.userId;
        state.customerId = payload.customerId || state.customerId;
        state.customerIds = payload.customerIds || state.customerIds;
        state.toolIds = payload.toolIds || state.toolIds;
        state.referenceId = payload.referenceId || state.referenceId;
        state.reportvalue = payload.reportvalue || state.reportvalue;
        state.auditFinanceCustomer =
          payload.auditFinanceCustomer || state.auditFinanceCustomer;
        state.auditFinanceFromDate =
          payload.auditFinanceFromDate || state.auditFinanceFromDate;
        state.auditFinanceToDate =
          payload.auditFinanceToDate || state.auditFinanceToDate;
        state.FinanceCustomerName =
          payload.FinanceCustomerName || state.FinanceCustomerName;
        state.ToolsComName = payload.ToolsComName || state.ToolsComName;
        state.ToolsCustomerId =
          payload.ToolsCustomerId || state.ToolsCustomerId;
        state.ToolsapiToken = payload.ToolsapiToken || state.ToolsapiToken;
        (state.AppSheetToolsComName =
          payload.AppSheetToolsComName || state.AppSheetToolsComName),
          (state.AppSheetToolsCustomerId =
            payload.AppSheetToolsCustomerId || state.AppSheetToolsCustomerId),
          (state.AppSheetToolsapiToken =
            payload.AppSheetToolsapiToken || state.AppSheetToolsapiToken),
          (state.reportId = payload.reportId || state.reportId),
          (state.companyName = payload.companyName || state.companyName);
        state.companyAppsheetId = payload.companyAppsheetId || state.companyAppsheetId;
        state.supportTicketsReferenceId =
          payload.supportTicketsReferenceId || state.supportTicketsReferenceId;
        state.assetWizardValue =
          payload.assetWizardValue || state.assetWizardValue;
        state.serviceWizardValue =
          payload.serviceWizardValue || state.serviceWizardValue;
        state.assetRefreshVal =
          payload.assetRefreshVal || state.assetRefreshVal;
        state.serviceRefreshVal =
          payload.serviceRefreshVal || state.serviceRefreshVal;
        state.activeTab = payload.activeTab || state.activeTab;
        state.assetBatchId = payload.assetBatchId || state.assetBatchId;
        state.serviceBatchId = payload.serviceBatchId || state.serviceBatchId;
        state.setErrorMessageLogin = payload.setErrorMessageLogin || state.setErrorMessageLogin;
        state.loggedInReferenceId =
          payload.loggedInReferenceId || state.loggedInReferenceId;
        state.searchMasterData =
          payload.searchMasterData || state.searchMasterData;
        state.searchMachineId =
          payload.searchMachineId || state.searchMachineId;
        state.assetId = payload.assetId || state.assetId;
        state.tuSerialNumber =
        payload.tuSerialNumber || state.tuSerialNumber;
        state.serviceTypeActual =
        payload.serviceTypeActual || state.serviceTypeActual;
        state.serviceDescription =
        payload.serviceDescription || state.serviceDescription;
        state.brand = payload.brand || state.brand;
        state.machineType = payload.machineType || state.machineType;
        state.model = payload.model || state.model;
        state.serviceId =
        payload.serviceId || state.serviceId;
        state.verificationType =
        payload.verificationType || state.verificationType;
        state.engineType =
        payload.engineType || state.engineType;
        state.pageSize = payload.pageSize || state.pageSize;
        state.servicePhotosList =
          payload.servicePhotosList || state.servicePhotosList;
        state.DefitServicePhotosList =
          payload.DefitServicePhotosList || state.DefitServicePhotosList;
        state.CRId = payload.CRId || state.CRId;
        state.cityName = payload.cityName || state.cityName;
        state.auditPageSize = payload.auditPageSize || state.auditPageSize;
        state.deviceFK = payload.deviceFK || state.deviceFK;
        state.serviceLastUpdated = payload.serviceLastUpdated || state.serviceLastUpdated;
        (state.serviceType = payload.serviceType || state.serviceType),
          (state.vendorName = payload.vendorName || state.vendorName);
        state.validServiceType =
          payload.validServiceType || state.validServiceType;
          state.branchDetails = payload.branchDetails || state.branchDetails;
          state.branchId = payload.branchId || state.branchId;
          state.branchName = payload.branchName || state.branchName;
          state.branchGuId = payload.branchGuId || state.branchGuId;
          state.contactedThroughPhone = payload.contactedThroughPhone || state.contactedThroughPhone;
          state.contactedThroughEmail = payload.contactedThroughEmail || state.contactedThroughEmail;
          state.verificationKey = payload.verificationKey || state.verificationKey;
          state.nruInspFlowType = payload.nruInspFlowType || state.nruInspFlowType;
          state.activityCompanyId = payload.activityCompanyId || state.activityCompanyId;
          state.pageNumber = payload.pageNumber || state.pageNumber;
          state.sortBy = payload.sortBy || state.sortBy;
          state.sortDesc = payload.sortDesc || state.sortDesc;
      }
    },
    SET_ACCESS_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_REFRESH_TOKEN: (state, refreshToken) => {
      state.refreshToken = refreshToken;
    },
    SET_TOKEN_EXPIRY_TIME: (state, tokenExpiryTime) => {
      state.tokenExpiryTime = tokenExpiryTime;
    },
    REPORT_VALUE: (state, value) => {
      state.reportvalue = value;
    },
    REFERENCE_ID: (state, value) => {
      state.referenceId = value;
    },
    SET_Customer: (state, value) => {
      state.auditFinanceCustomer = value;
    },
    SET_FromDate: (state, value) => {
      state.auditFinanceFromDate = value;
    },
    SET_ToDate: (state, value) => {
      state.auditFinanceToDate = value;
    },
    SET_CUSTOMERNAME: (state, value) => {
      state.FinanceCustomerName = value;
    },
    SET_TOOL_COMNAME: (state, value) => {
      state.ToolsComName = value;
    },
    SET_TOOL_CUSTOMERID: (state, value) => {
      state.ToolsCustomerId = value;
    },
    SET_TOOL_APITOKEN: (state, value) => {
      state.ToolsapiToken = value;
    },
    SET_APPSHEET_COMNAME: (state, value) => {
      state.AppSheetToolsComName = value;
    },
    SET_APPSHEET_CUSTOMERID: (state, value) => {
      state.AppSheetToolsCustomerId = value;
    },
    SET_APPSHEET_APITOKEN: (state, value) => {
      state.AppSheetToolsapiToken = value;
    },
    SET_REPORTID: (state, value) => {
      state.reportId = value;
    },
    SET_COMPANYNAME: (state, value) => {
      state.companyName = value;
    },
    SET_COMPANY_APPSHEET_ID: (state, value) => {
      state.companyAppsheetId = value;
    },
    SET_SUPPORT_REF_ID: (state, value) => {
      state.supportTicketsReferenceId = value;
    },
    SET_ASSET_WIZARD_VALUE: (state, value) => {
      state.assetWizardValue = value;
    },
    SET_SERVICE_WIZARD_VALUE: (state, value) => {
      state.serviceWizardValue = value;
    },
    SET_ASSET_REFRESH_VAL: (state, value) => {
      state.assetRefreshVal = value;
    },
    SET_SERVICE_REFRESH_VAL: (state, value) => {
      state.serviceRefreshVal = value;
    },
    SET_ACTIVE_TAB: (state, value) => {
      state.activeTab = value;
    },
    // assetBatchId
    SET_ASSET_BATCH_ID: (state, value) => {
      state.assetBatchId = value;
    },
    // serviceBatchId
    SET_SERVICE_BATCH_ID: (state, value) => {
      state.serviceBatchId = value;
    },
    SET_ERROR_MESSAGE_LOGIN:(state, value) => {
      state.setErrorMessageLogin = value;
    },
    // loggedInReferenceId
    SET_LOGGEDINREFERENCEID: (state, value) => {
      state.loggedInReferenceId = value;
    },
    SET_SEARCH_MASTER_DATA: (state, value) => {
      state.searchMasterData = value;
    },
    SET_SEARCH_MASTER_MACHINE_ID: (state, value) => {
      state.searchMachineId = value;
    },
    SET_ASSET_ID: (state, value) => {
      state.assetId = value;
    },
    SET_TU_SERIAL_NUMBER: (state, value) => {
      state.tuSerialNumber = value;
    },
    SET_SERVICE_TYPE_ACTUAL: (state, value) => {
      state.serviceTypeActual = value;
    },
    SET_SERVICE_DESCRIPTION: (state, value) => {
      state.serviceDescription = value;
    },
    SET_BRAND: (state, value) => {
      state.brand = value;
    },
    SET_MACHINE_TYPE: (state, value) => {
      state.machineType = value;
    },
    SET_MODEL: (state, value) => {
      state.model = value;
    },
    SET_SERVICE_ID: (state, value) => {
      state.serviceId = value;
    },
    SET_VERIFICATION_TYPE: (state, value) => {
      state.verificationType = value;
    },
    SET_ENGINE_TYPE: (state, value) => {
      state.engineType = value;
    },
    SET_PAGE_SIZE: (state, value) => {
      state.pageSize = value;
    },
    SET_SERVICE_PHOTOS: (state, value) => {
      state.servicePhotosList = value;
    },

    SET_DEFIT_SERVICE_PHOTOS: (state, value) => {
      state.DefitServicePhotosList = value;
    },
    SET_CR_ID: (state, value) => {
      state.CRId = value;
    },
    SET_CITY_NAME: (state, value) => {
      state.cityName = value;
    },
    SET_AUDIT_PAGE: (state, value) => {
      state.auditPageSize = value;
    },
    SET_DEVICE_FK: (state, value) => {
      state.deviceFK = value;
    },
    SET_SERVICE_TYPE: (state, value) => {
      state.serviceType = value;
    },
    SET_SERVICE_LAST_UPDATED: (state, value) => {
      state.serviceLastUpdated = value;
    },
    //In vendorName we are storing loggedIn contractorId
    SET_VENDOR_NAME: (state, value) => {
      state.vendorName = value;
    },
    SET_CUSTOMER_IDS: (state, value) => {
      state.customerIds = value;
    },
    SET_VALID_SERVICE: (state, value) => {
      state.validServiceType = value;
    },
    SET_BRANCH_INFO: (state, value) => {
      state.branchDetails = value;
    },
    SET_BRANCH_ID: (state, value) => {
      state.branchId = value;
    },
    SET_BRANCH_NAME: (state, value) => {
      state.branchName = value;
    },
    SET_BRANCH_GUID: (state, value) => {
      state.branchGuId = value;
    },
    SET_CONTACTED_THROUGH_PHONE: (state, value) => {
      state.contactedThroughPhone = value;
    },
    SET_CONTACTED_THROUGH_EMAIL: (state, value) => {
      state.contactedThroughEmail = value;
    },
    SET_VERIFICATION_KEY: (state, value) => {
      state.verificationKey = value;
    },
    SET_NRU_INSP_FLOW_TYPE: (state, value) => {
      state.nruInspFlowType = value;
    },
    SET_ACTIVITY_COMPANY_ID: (state, value) => {
      state.activityCompanyId = value;
    },
    SET_PAGE_NUMBER:(state, value) => {
      state.pageNumber = value;
    },
    SET_SORT_BY:(state, value) => {
      state.sortBy = value;
    },
    SET_SORT_DESC: (state, value) => {
      state.sortDesc = value;
    },
    SET_MACHINE_YEAR: (state, value) => {
      state.machineYear = value;
    },
    SET_MACHINE_CAN_ELIGIBLE_STATUS: (state, value) => {
      state.machineCanEligibleStatus = value;
    },
    SET_SUGGESTED_REFIT_INST_TYPE: (state, value) => {
      state.suggestedRefitInstType = value;
    },
    SET_VALID_REFIT_INST_TYPES: (state, value) => {
      state.validRefitInstTypes = value;
    },
  },

  actions: {
    // Login user dispatch
    LoginByEmail: async ({ commit }, payload) => {
      try {
        const response = await login.loginByEmail(
          payload.username.trim(),
          payload.password
        );
        if (response.isSuccess) {
          if (response.data) {
            await commit("SET_ACCESS_TOKEN", response.data.token.access_token);
            await commit(
              "SET_REFRESH_TOKEN",
              response.data.token.refresh_token
            );
            await commit(
              "SET_TOKEN_EXPIRY_TIME",
              response.data.token.expires_at
            );
            return true;
          } else{
            return false;
          }
        } else {
          this.$toast.error("Please enter valid credentials.");
        }
      } catch (err) {
        console.warn("[vuex.user] LoginByEmail", err);
      }
    },
    // Login user dispatch
    UserInfo: async ({ commit }, payload) => {
      try {
        const response = await userService.getUser(
          payload.userId
        );
        if (response.isSuccess) {
          if (response.data && JSON.stringify(response.data) !== "{}") {
            // response.userInformation.access_token = uuidv4();
            response.data.flName =
              response.data.firstName +
              " " +
              response.data.lastName;
            response.data.username = response.data.email;
            response.data.userId = response.data.id;
            for (var a=0; a<response.data.userMappings.length; a++) {
              if (response.data.userMappings[a].role["name"].split("-")[0] == 'PWA') {
                
                response.data["userRole"] = response.data.userMappings[a].role["name"].split("-")[1];
                // only show this selected customers in all customers dropdown?
                response.data["customerIds"] = response.data.userMappings[a]["customerIds"] ? response.data.userMappings[a]["customerIds"].split(",") : "";
                response.data["referenceId"] = response.data.userMappings[a]["customerIds"];
                response.data["vendorId"] = response.data.userMappings[a]["contractorIds"];
                // only show this selected tools in all customers dropdown?
                response.data["toolIds"] = response.data.userMappings[a]["toolIds"] ? response.data.userMappings[a]["toolIds"].split(",") : "";
              } 
            }
            await commit("SET_USER_INFO", response.data);
            await commit(
              "SET_LOGGEDINREFERENCEID",
              response.data.referenceId
            );
            ////set default customer values to store
            let referenceIdsList = response.data.referenceId ? response.data.referenceId.split(",") : [];
            if (response.data.defaultCustomerId && response.data.defaultCustomerName && (referenceIdsList.length > 0 || response.data["userRole"] == "Manager") && (referenceIdsList.includes(response.data.defaultCustomerId) || response.data["userRole"] == "Manager")) {
              await commit("SET_REPORTID", response.data.defaultCustomerId);
              await commit("SET_CR_ID", response.data.defaultCustomerId);
              await commit("SET_COMPANYNAME", response.data.defaultCustomerName);
            } else {
              await commit("SET_REPORTID", "");
              await commit("SET_CR_ID", "");
              await commit("SET_COMPANYNAME", "");
            }
            await commit("REFERENCE_ID", response.data.defaultCustomerId);
            await commit(
              "SET_SUPPORT_REF_ID",
              response.data.supportReferenceId
            );
            await commit("SET_VENDOR_NAME", response.data.vendorId);
            await commit("SET_CUSTOMER_IDS", response.data.customerIds);
            // await commit("SET_REPORTID", null);
            // await commit("SET_COMPANYNAME", null);
            // await commit("REFERENCE_ID", null);
            if (response.data.userRole != "User" && response.data.userRole != "Manager") {
              return false;
            } else {
              return true;
            }
          } else {
            return false;
          }
        } else {
          return false;
        }
      } catch (err) {
        console.warn("[vuex.user] UserInfo", err);
        return false;
      }
    },
    // Login user dispatch
    getAuthToken: async ({ commit }, payload) => {
      try {
        const response = await login.getAuthToken(
          payload.token
        );
        if (response.isSuccess) {
          
          if (response.data) {
            
            await commit("SET_ACCESS_TOKEN", response.data.token.access_token);
            await commit(
              "SET_REFRESH_TOKEN",
              response.data.token.refresh_token
            );
            await commit(
              "SET_TOKEN_EXPIRY_TIME",
              response.data.token.expires_at
            );
            if (response.data.user && JSON.stringify(response.data.user) !== "{}") {
              // response.userInformation.access_token = uuidv4();
              response.data.user.flName =
                response.data.user.firstName +
                " " +
                response.data.user.lastName;
              response.data.user.username = response.data.user.email;
              response.data.user.userId = response.data.user.id;
              for (var a=0; a<response.data.user.userMappings.length; a++) {
                if (response.data.user.userMappings[a].role["name"].split("-")[0] == 'PWA') {
                  
                  response.data.user["userRole"] = response.data.user.userMappings[a].role["name"].split("-")[1];
                  // only show this selected customers in all customers dropdown?
                  response.data.user["customerIds"] = response.data.user.userMappings[a]["customerIds"] ? response.data.user.userMappings[a]["customerIds"].split(",") : "";
                  response.data.user["referenceId"] = response.data.user.userMappings[a]["customerIds"];
                  response.data.user["vendorId"] = response.data.user.userMappings[a]["contractorIds"];
                  // only show this selected tools in all customers dropdown?
                  response.data.user["toolIds"] = response.data.user.userMappings[a]["toolIds"] ? response.data.user.userMappings[a]["toolIds"].split(",") : "";
                } 
              }
              await commit("SET_USER_INFO", response.data.user);
              await commit(
                "SET_LOGGEDINREFERENCEID",
                response.data.user.referenceId
              );
              ////set default customer values to store
              let referenceIdsList = response.data.user.referenceId ? response.data.user.referenceId.split(",") : [];
              if (response.data.user.defaultCustomerId && response.data.user.defaultCustomerName && (referenceIdsList.length > 0 || response.data.user["userRole"] == "Manager") && (referenceIdsList.includes(response.data.user.defaultCustomerId) || response.data.user["userRole"] == "Manager")) {
                await commit("SET_REPORTID", response.data.user.defaultCustomerId);
                await commit("SET_CR_ID", response.data.user.defaultCustomerId);
                await commit("SET_COMPANYNAME", response.data.user.defaultCustomerName);
              } else {
                await commit("SET_REPORTID", "");
                await commit("SET_CR_ID", "");
                await commit("SET_COMPANYNAME", "");
              }
              await commit("REFERENCE_ID", response.data.user.defaultCustomerId);
              await commit(
                "SET_SUPPORT_REF_ID",
                response.data.user.supportReferenceId
              );
              await commit("SET_VENDOR_NAME", response.data.user.vendorId);
              await commit("SET_CUSTOMER_IDS", response.data.user.customerIds);
              if (response.data.user.userRole != "User" && response.data.user.userRole != "Manager") {
                await commit("SET_ERROR_MESSAGE_LOGIN", response.systemMessage);
                return false;
              } else {
                await commit("SET_ERROR_MESSAGE_LOGIN", "");
                return true;
              }
            } else {
              await commit("SET_ERROR_MESSAGE_LOGIN", response.systemMessage);
              return false;
            }
          } else {
            await commit("SET_ERROR_MESSAGE_LOGIN", response.systemMessage);
              return false;
          }
        } else {
          await commit("SET_ERROR_MESSAGE_LOGIN", response.systemMessage);
            return false;
        }
      } catch (err) {
        console.warn("[vuex.user] getAuthToken", err);
      }
    },
    // GetCustomerInfo user dispatch
    GetCustomerInfo: async ({ commit }, payload) => {
      try {
        const response = await fitService.getContractorCustomers(
          payload.contractorId
        );
        if (response.isSuccess) {
          if (response.data) {
            await commit(
              "SET_LOGGEDINREFERENCEID",
              response.data.join(",")
            );
            await commit("SET_CUSTOMER_IDS", response.data);
            ////set default customer values to store
            let referenceIdsList = response.data;
            if (payload.store.getters.CRId && payload.store.getters.companyName && referenceIdsList.length > 0 && referenceIdsList.includes(payload.store.getters.CRId)) {
              await commit("SET_REPORTID", payload.store.getters.CRId);
              await commit("SET_CR_ID", payload.store.getters.CRId);
              await commit("SET_COMPANYNAME", payload.store.getters.companyName);
            } else {
              await commit("SET_REPORTID", "");
              await commit("SET_CR_ID", "");
              await commit("SET_COMPANYNAME", "");
            }
            await commit("REFERENCE_ID", payload.store.getters.CRId);
            await commit("SET_ERROR_MESSAGE_LOGIN", "");
            return true;
          } else {
            await commit("SET_ERROR_MESSAGE_LOGIN", "Unable to fetch user profile details, Please try again");
            return false;
          }
        } else {
          await commit("SET_ERROR_MESSAGE_LOGIN", "Unable to fetch user profile details, Please try again");
          return false;
        }
      } catch (err) {
        console.warn("[vuex.user] GetCustomerInfo", err);
        return false;
      }
    },
    setDeviceFK: async (context, value) => {
      context.commit("SET_DEVICE_FK", value);
    },
    setServiceType: async ({ commit }, value) => {
      commit("SET_SERVICE_TYPE", value);
    },
    setServiceLastUpdated: async ({ commit }, value) => {
      commit("SET_SERVICE_LAST_UPDATED", value);
    },
    Reportvalue: async (context, value) => {
      context.commit("REPORT_VALUE", value);
    },
    ReferenceIdValue: async ({ commit }, value) => {
      commit("REFERENCE_ID", value);
    },
    setCustomer: async (context, value) => {
      context.commit("SET_Customer", value);
    },
    setFromDate: async (context, value) => {
      context.commit("SET_FromDate", value);
    },
    setToDate: async (context, value) => {
      context.commit("SET_ToDate", value);
    },
    setCustomerName: async (context, value) => {
      context.commit("SET_CUSTOMERNAME", value);
    },
    setToolCustomerName: async (context, value) => {
      context.commit("SET_TOOL_COMNAME", value);
    },
    setToolCustomerId: async (context, value) => {
      context.commit("SET_TOOL_CUSTOMERID", value);
    },
    setToolapiToken: async (context, value) => {
      context.commit("SET_TOOL_APITOKEN", value);
    },
    setAppSheetCustomerName: async (context, value) => {
      context.commit("SET_APPSHEET_COMNAME", value);
    },
    setAppSheetCustomerId: async (context, value) => {
      context.commit("SET_APPSHEET_CUSTOMERID", value);
    },
    setAppSheetapiToken: async (context, value) => {
      context.commit("SET_APPSHEET_APITOKEN", value);
    },
    setReportId: async (context, value) => {
      context.commit("SET_REPORTID", value);
    },
    setCompanyName: async (context, value) => {
      context.commit("SET_COMPANYNAME", value);
    },
    setCompanyAppsheetId: async (context, value) => {
      context.commit("SET_COMPANY_APPSHEET_ID", value);
    },
    setSupportRefId: async (context, value) => {
      context.commit("SET_SUPPORT_REF_ID", value);
    },
    setAssetWizardValue: async (context, value) => {
      context.commit("SET_ASSET_WIZARD_VALUE", value);
    },
    setServiceWizardValue: async (context, value) => {
      context.commit("SET_SERVICE_WIZARD_VALUE", value);
    },
    setAssetRefreshVal: async (context, value) => {
      context.commit("SET_ASSET_REFRESH_VAL", value);
    },
    setServiceRefreshVal: async (context, value) => {
      context.commit("SET_SERVICE_REFRESH_VAL", value);
    },
    setActiveTabVal: async (context, value) => {
      context.commit("SET_ACTIVE_TAB", value);
    },
    setAssetBatchId: async (context, value) => {
      context.commit("SET_ASSET_BATCH_ID", value);
    },
    setServiceBatchId: async (context, value) => {
      context.commit("SET_SERVICE_BATCH_ID", value);
    },
    setErrorMessageLogin:async (context, value) => {
      context.commit("SET_ERROR_MESSAGE_LOGIN",value);
    },
    setLoggedInReferenceId: async (context, value) => {
      context.commit("SET_LOGGEDINREFERENCEID", value);
    },
    //Store search by page data
    setSearchMasterData: async (context, value) => {
      context.commit("SET_SEARCH_MASTER_DATA", value);
    },
    setSearchMasterMachineId: async (context, value) => {
      context.commit("SET_SEARCH_MASTER_MACHINE_ID", value);
    },
    setAssetId: async (context, value) => {
      context.commit("SET_ASSET_ID", value);
    },
    setTUSerialNumber: async (context, value) => {
      context.commit("SET_TU_SERIAL_NUMBER", value);
    },
    setServiceTypeActual: async (context, value) => {
      context.commit("SET_SERVICE_TYPE_ACTUAL", value);
    },
    setServiceDescription: async (context, value) => {
      context.commit("SET_SERVICE_DESCRIPTION", value);
    },
    setBrand: async (context, value) => {
      context.commit("SET_BRAND", value);
    },
    setMachineType: async (context, value) => {
      context.commit("SET_MACHINE_TYPE", value);
    },
    setModel: async (context, value) => {
      context.commit("SET_MODEL", value);
    },
    setServiceId: async (context, value) => {
      context.commit("SET_SERVICE_ID", value);
    },
    setVerificationType: async (context, value) => {
      context.commit("SET_VERIFICATION_TYPE", value);
    },
    setEngineType: async (context, value) => {
      context.commit("SET_ENGINE_TYPE", value);
    },
    setPageSize: async (context, value) => {
      context.commit("SET_PAGE_SIZE", value);
    },
    setServicePhotos: async (context, value) => {
      context.commit("SET_SERVICE_PHOTOS", value);
    },
    setDefitServicePhotos: async (context, value) => {
      context.commit("SET_DEFIT_SERVICE_PHOTOS", value);
    },
    setCRId: async (context, value) => {
      context.commit("SET_CR_ID", value);
    },
    setCityName: async (context, value) => {
      context.commit("SET_CITY_NAME", value);
    },
    setAuditPageSize: async (context, value) => {
      context.commit("SET_AUDIT_PAGE", value);
    },
    setValidService: async (context, value) => {
      context.commit("SET_VALID_SERVICE", value);
    },
    setBranchInfo: async (context, value) => {
      context.commit("SET_BRANCH_INFO", value);
    },
    setBranchId: async (context, value) => {
      context.commit("SET_BRANCH_ID", value);
    },
    setBranchName: async (context, value) => {
      context.commit("SET_BRANCH_NAME", value);
    },
    setBranchGuId: async (context, value) => {
      context.commit("SET_BRANCH_GUID", value);
    },
    setContactedThroughPhone: async (context, value) => {
      context.commit("SET_CONTACTED_THROUGH_PHONE", value);
    },
    setContactedThroughEmail: async (context, value) => {
      context.commit("SET_CONTACTED_THROUGH_EMAIL", value);
    },
    setVerificationKey: async (context, value) => {
      context.commit("SET_VERIFICATION_KEY", value);
    },
    setNruInspFlowType: async (context, value) => {
      context.commit("SET_NRU_INSP_FLOW_TYPE", value);
    },
    setActivityCompanyId: async (context, value) => {
      context.commit("SET_ACTIVITY_COMPANY_ID", value);
    },
    setPageNumber:async (context, value) => {
      context.commit("SET_PAGE_NUMBER",value);
    },
    setSortBy:async (context, value) => {
      context.commit("SET_SORT_BY",value);
    },
    setSortDesc: async (context, value) => {
      context.commit("SET_SORT_DESC", value);
    },
    setMachineYear: async (context, value) => {
      context.commit("SET_MACHINE_YEAR", value);
    },
    setMachineCanEligibleStatus: async (context, value) => {
      context.commit("SET_MACHINE_CAN_ELIGIBLE_STATUS", value);
    },
    setSuggestedRefitInstType: async (context, value) => {
      context.commit("SET_SUGGESTED_REFIT_INST_TYPE", value);
    },
    setValidRefitInstTypes: async (context, value) => {
      context.commit("SET_VALID_REFIT_INST_TYPES", value);
    },
    GetUserInfo: async () => {
      try {
        const response = false;

        // Since mockjs does not support custom status codes, it can only be hacked like this
        if (!response) {
          throw new Error("Verification failed, please login again.");
        }

        // Verify returned roles are a non-null array
        if (response.user.roles && response.user.roles.length === 0) {
          throw new Error("getInfo: roles must be a non-null array!");
        }

        //commit('SET_USER_INFO', response.user);
      } catch (err) {
        console.warn("[vuex.user] GetUserInfo", err);
      }
    },

    LogOut: async ({ commit }) => {
      try {
        await commit("SET_USER_INFO", { logout: true });
        let a = await caches.delete('cache');
        console.log(a)
        // localStorage.clear();
        localStorage.removeItem('vuex'); 
        localStorage.removeItem('okta-cache-storage');
        localStorage.removeItem('okta-pkce-storage');
        localStorage.removeItem('okta-token-storage');
        // localStorage.removeItem('loglevel:webpack-dev-server');
      } catch (err) {
        console.warn("[vuex.user] LogOut", err);
      }
    },
  },
};

export default user;
