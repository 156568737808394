import NProgress from "nprogress"; // progress bar
import router from "./index";
import store from "../store";
import authRouter from "./modules/auth";
import "nprogress/nprogress.css"; // progress bar style

NProgress.configure({ showSpinner: false }); // NProgress Configuration

/**
 * Generate white list
 */
const whiteList = []
  .concat(Array.from(authRouter, (route) => route.path))
  .concat(Array.from(authRouter, (route) => route.alias))
  .filter((route) => route);

const hasPermission = (roles, route) => {
  if (route.meta && route.meta.roles) {
    return route.meta.roles.includes(roles);
  }
  return true;
};

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  try {
    if (store.getters.token) {
      if (whiteList.includes(to.path)) {
        next({ path: "/" });
      } else {
        if (!store.getters.roles || !store.getters.user) {
          await store.dispatch("LogOut");
          next({ path: "/signin" });
        } else {
          if (!hasPermission(store.getters.roles, to)) {
            next({ path: "/" });
          } else{
            if (to.params.key) {
                to.meta.title = to.params.key
            }
            next();
          }
         
        }
      }
    } else {
      if (whiteList.includes(to.path)) {
        next();
      } else {
        // next(`/signin?redirect=${to.path}`);
        next({ path: "/signin" });
      }
    }
  } catch (err) {
    console.warn(`[router.beforeEach]\t${to.path}: ${err}`);
  }
  NProgress.done();
});

router.afterEach(async () => {
  NProgress.done();
});
