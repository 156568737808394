import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VueAxios from "vue-axios";
import axios from "axios";
import "vuetify/dist/vuetify.min.css";
import "./assets/styles/styles.scss";
import './router/permission';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import Buefy from "buefy";
import "buefy/dist/buefy.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";
import VueMask from 'v-mask';
import config from "./config";
// interceptor();
import { BarcodeReader } from 'dynamsoft-javascript-barcode';
// BarcodeReader.license = 'DLS2eyJvcmdhbml6YXRpb25JRCI6IjIwMDAwMSJ9';
// first 30days trail id BarcodeReader.license = 'DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAxMTk0MTAxLVRYbFhaV0pRY205cVgyUmljZyIsIm9yZ2FuaXphdGlvbklEIjoiMTAxMTk0MTAxIn0=';
// BarcodeReader.license = 'DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAxMTk0MTAxLVRYbFhaV0pRY205cVgyUmljZyIsIm9yZ2FuaXphdGlvbklEIjoiMTAxMTk0MTAxIiwiY2hlY2tDb2RlIjo2MDM4MTUxNzd9';
// below initial r account 
// BarcodeReader.license = 'DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAxMzg1NDMwLVRYbFhaV0pRY205cVgyUmljZyIsIm9yZ2FuaXphdGlvbklEIjoiMTAxMzg1NDMwIiwiY2hlY2tDb2RlIjozMTk2NTI0Nzl9';
// below m account 
// BarcodeReader.license = 'DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAxNTE4MzM1LVRYbFhaV0pRY205cVgyUmljZyIsIm9yZ2FuaXphdGlvbklEIjoiMTAxNTE4MzM1IiwiY2hlY2tDb2RlIjotMTY5MDE0OTIwMX0=';

// below m account latest
BarcodeReader.license = config.BARCODE_LICENSE;
      
BarcodeReader.engineResourcePath = config.BARCODE_LICENSE_PATH;
Vue.config.productionTip = false
Vue.use(Viewer);
Vue.use(VueMask);
Vue.use(Buefy);
Vue.use(VueAxios, axios);
Vue.use(VueToast, {
  position: "bottom",
  duration: 5000,
  dismissible: true,
  queue: true
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
