// import axios from "axios";
// import env from "../config";
import axios from "../api/axios";
// const client = axios.create({
//   baseURL: env.API_ENDPOINT,
//   // baseURL: `http://35.236.93.48/api/Client`,
//   json: true
// });

export default {
  async execute(method, resource, data) {
    return axios.apiEndPoint({
      method,
      url: resource,
      data
    }).then(req => {
      return req.data;
    });
  },
  getUserInfoById() {
    return this.execute("get", "/User")
  },
  updateUser(payload, userId) {
    return this.execute("put", `/user/${userId}`, payload);
  },
  getUserByID(id) {
    return this.execute("get", "registration/user/" + id);
  },
  getProfile(userId) {
    return this.execute("get", `/Profile/${userId}`);
  },
  setdefaultCustomer(payload){
    return this.execute("put",`/Customer/defaultCustomer`,payload)
  },
};
