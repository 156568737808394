import moment from "moment";
import { mapGetters } from "vuex";
import InfoDialog from "@/components/InfoDialog";
import BranchInfoDialog from "@/components/BranchInfoDialog";
import EventBus from "../../utils/EventBus";
import BranchCheckInfoDialog from "@/components/BranchCheckInfoDialog";
export default {
  name: "pageheader",
  components: { InfoDialog , BranchInfoDialog, BranchCheckInfoDialog},
  props: [
    "input",
  ],
  data() {
    return {
      openedPanel: [],
      isExpanded: false,
      showbanner:true,
      currentRoute:null,
      currentRouteName:null,
      description: "",
      serviceType: "",
      totalAssetsCheckedInCount: ""
    };
  },
  computed: {
    ...mapGetters([
      "companyName","searchMachineId","tuSerialNumber","serviceTypeActual","serviceDescription","branchDetails"
    ]),
  },
  filters: {
    formatDate: function(value) {
      if (value) {
        const format = "YYYY-MM-DD";
        return moment(value).format(format);
      }
    },
    lowercase: function(value) {
      if (value) {
        return value.toLowerCase();
      }
    },
    companyNameDisplay: function(value) {
      if (value) {
        return value.length > 21 ? value.substring(0,21) + "..." : value;
      }
    },
  },
  mounted() {
    this.handleRouter();
    EventBus.$on('total-assets-checkedIn', (count) => {
      this.totalAssetsCheckedInCount = count;
    });
  },
  watch: {
    deep: true,
    immediate: true,
    $route:function() {
      this.handleRouter();
    }
  },
  methods: {
    openServiceDescription() {
      if (this.serviceDescription) {
        this.description = this.serviceDescription;
      }
      if (this.serviceTypeActual) {
        this.serviceType = this.serviceTypeActual;
      }
      this.$refs.modal.showModal();
    },
    handleRouter(){
      this.currentRoute = this.$route.fullPath;
      this.currentRouteName = this.$route.name;
  },
    handleToggle() {
      if (this.isExpanded) {
        this.openedPanel = [0];
        this.showbanner = false;
      } else {
        this.openedPanel = [1];
        this.showbanner = true;
      }
    },
    toggleClick() {
      this.isExpanded = !this.isExpanded;
      this.handleToggle();
    },
    openModal() {
      this.$refs.modal.showModal();
    },
    openBranchCheckModal() {
      this.$refs.branchCheckInfoModel.showModal();
    },
    openBranchModal(){
      this.$refs.branchModal.openDialog();
    }
  },
};
