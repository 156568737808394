
export default {
  name: "info-dialog",
  components: {},
  props: ["description", "serviceType"],
  data () {
    return {
      infoDialog: false
    };
  },
  computed: {},
  mounted () {},
  methods: {
    showModal() {
      this.infoDialog = true;
    },
  }
};
