export default {
  name: "branch-check-info-dialog",
  components: {},
  props: ["isReviewList"],
  data () {
    return {
      branchCheckInfoDialog: false
    };
  },
  computed: {},
  mounted () {},
  methods: {
    showModal() {
      this.branchCheckInfoDialog = true;
    },
  }
};