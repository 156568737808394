<template>
  <v-list :dense="dense" class="layout-drawer">
    <div
      @click="toggleNavbar(item)"
      v-for="item in navigation.filter((item) => !item.hidden)"
      :key="item.title"
    >
      <v-list-item
        v-if="isVisibleItem(item)"
        :to="resolvePath(onlyOneChild.path)"
        ripple="ripple"
        :class="item.name === parentPath ? 'v-list-item--active' : ''"
      >
        <v-list-item-icon class="layout-drawer__icon">
          <v-icon>{{ getListIcon(onlyOneChild) }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="menu-title">
            {{ getListTitle(onlyOneChild) }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-group
        v-else-if="item.name == 'Sales'"
        :value="isActiveSales"
        :prepend-icon="getListIcon(item)"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title class="menu-title group-name">
              {{ getListTitle(item) }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <template v-for="(subItem, index) in item.children">
          <v-list-item
            :key="index"
            v-if="isVisibleItem(subItem)"
            :to="resolvePath(subItem.path)"
            ripple="ripple"
            :class="subItem.name === parentPath ? 'v-list-item--active' : ''"
          >
            <v-list-item-icon class="layout-drawer__icon">
              <v-icon>{{ getListIcon(subItem) }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="menu-title">
                {{ getListTitle(subItem) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <!-- <the-layout-drawer-list
          :dense="dense"
          :routes="item.children"
          :base-path="resolvePath(item.path)"
        /> -->
        <!-- <div v-for="(route, index) in item.children" :key="index">
          <a :href="route.path" target="_blank">{{route.name}}</a>
        </div> -->
      </v-list-group>
      <v-list-group v-else :prepend-icon="getListIcon(item)" :value="isActive">
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title class="menu-title group-name">
              {{ getListTitle(item) }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <template v-for="(subItem, index) in item.children">
          <v-list-item
            :key="index"
            v-if="isVisibleItem(subItem)"
            :to="resolvePath(subItem.path)"
            ripple="ripple"
            :class="subItem.name === parentPath ? 'v-list-item--active' : ''"
          >
            <v-list-item-icon class="layout-drawer__icon">
              <v-icon>{{ getListIcon(subItem) }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="menu-title">
                {{ getListTitle(subItem) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <!-- <the-layout-drawer-list
          :dense="dense"
          :routes="item.children"
          :base-path="resolvePath(item.path)"
        /> -->
        <!-- <div v-for="(route, index) in item.children" :key="index">
          <a :href="route.path" target="_blank">{{route.name}}</a>
        </div> -->
      </v-list-group>
    </div>
  </v-list>
</template>
<script>
import { resolve } from "path";
import { mapGetters } from "vuex";
export default {
  name: "TheLayoutDrawerList",
  props: {
    dense: Boolean,
    iconShow: Boolean,
    isNest: Boolean,
    routes: {
      type: Array,
      default: () => {},
    },
    basePath: {
      type: String,
      default: "",
    },
  },
  data() {
    this.onlyOneChild = null;
    return {
      navigation: this.routes,
      parentPath: null,
    };
  },
  computed: {
    ...mapGetters(["roles", "companyName"]),
    isActive() {
      if (
        this.parentPath == "User Management" ||
        this.parentPath == "Settings" ||
        this.parentPath == "Community"
      ) {
        return true;
      }
      return false;
    },
    isActiveSales() {
      if (
        this.parentPath == "Clients" ||
        this.parentPath == "Contacts" ||
        this.parentPath == "Quotes" ||
        this.parentPath == "Sales Goals"
      ) {
        return true;
      }
      return false;
    },
  },
  created() {
    this.parentPath = this.$route.matched[0].name;
  },
  methods: {
    toggleNavbar(item) {
      this.$store.dispatch("NavbarToggle");
      if (item.name=="Discovery" && !this.companyName) {
        this.$router.push({ path: "/" }).catch(() => {});
        this.$toast.error("Please select a company to proceed to Discovery");
      } else if (item.name=="Status and Reporting" && !this.companyName) {
        this.$router.push({ path: "/" }).catch(() => {});
        this.$toast.error("Please select a company to proceed");
      } else if (item.name=="Equipment Records" && !this.companyName) {
        this.$router.push({ path: "/" }).catch(() => {});
        this.$toast.error("Please select a company to proceed");
      } else if (item.name=="Service Records" && !this.companyName) {
        this.$router.push({ path: "/" }).catch(() => {});
        this.$toast.error("Please select a company to proceed");
      }
    },
    isExternal(path) {
      return /^(https?:|mailto:|tel:)/.test(path);
    },
    isVisibleItem(item) {
      let test =
        this.hasOneVisibleChild(item.children, item) &&
        (!this.onlyOneChild.children || this.onlyOneChild.noVisibleChildren) &&
        !item.alwaysShow;
      return test;
    },
    hasOneVisibleChild(children = [], parent) {
      const visibleChildren = children.filter((item) => {
        if (item.hidden) return false;
        // Temp set(will be used if only has one visible child)
        this.onlyOneChild = item;
        return true;
      });
      // When there is only one child router, the child router is displayed by default
      if (visibleChildren.length === 1) {
        this.onlyOneChild.path = resolve(parent.path, this.onlyOneChild.path);
        this.onlyOneChild.meta.icon =
          this.onlyOneChild.meta.icon || parent.meta.icon || "";
        return true;
      }
      // Show parent if there are no child router to display
      if (visibleChildren.length === 0) {
        this.onlyOneChild = { ...parent, noVisibleChildren: true };
        return true;
      }
      return false;
    },
    resolvePath(path) {
      if (this.isExternal(path)) {
        return path;
      }
      return resolve(this.basePath, path);
    },
    getListIcon(item) {
      return this.iconShow && item.meta ? item.meta.icon : " ";
    },
    getListTitle(item) {
      return item.name ? item.name : "";
    },
  },
};
</script>
<style>
.layout-drawer {
  padding-bottom: 0px;
  padding-top: 0px;
}
.layout-drawer .v-icon {
  font-size: 32px !important;
}

.layout-drawer .v-list-item__icon {
  margin-right: 12px !important;
}
.menu-title {
  z-index: 1;
}
/* a.v-list-item--active.v-list-item.v-list-item--link.theme--dark{
    border-left: 2px solid #0065e5;
  }  old style */
</style>
