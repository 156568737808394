import { settings as defaultSettings, version } from '@/config';

const getDefaultSettings = () => {
  const settings = JSON.parse(JSON.stringify(defaultSettings));
  return settings;
};

const settings = {
  state: getDefaultSettings(), // Deep Clone

  getters: {
    version: () => version,
    toolbarDense: (state) => state.dense,
    navbarDense: (state) => state.navbar.dense,
    navbarShow: (state) => state.navbar.show,
    navbarLogo: (state) => state.navbar.logo,
    footerShow: (state) => state.footer,
    discoverySearchData: (state) => state.discoverySearchData
  },

  mutations: {
    SET_SETTINGS: (state, payload) => {
      state.dense = typeof payload.dense === 'boolean' ? payload.dense : state.dense;
      state.footer = typeof payload.dense === 'boolean' ? payload.footer : state.footer;
      state.navbar = payload.navbar || state.navbar;
    },
    TOOLBAR_DENSE_TOGGLE: (state) => {
      state.dense = !state.dense;
    },
    NAVBAR_DENSE_TOGGLE: (state) => {
      state.navbar.dense = !state.navbar.dense;
    },
    NAVBAR_LOGO_TOGGLE: (state) => {
      state.navbar.logo = !state.navbar.logo;
    },
    NAVBAR_TOGGLE: (state) => {
      state.navbar.show = !state.navbar.show;
    },
    NAVBAR_STATE: (state, payload) => {
      state.navbar.show = payload.state;
    },
    FOOTER_TOGGLE: (state) => {
      state.footer = !state.footer;
    },
    DISCOVERY_SEARCH_DATA: (state, payload) => {
      state.discoverySearchData = payload
    }
  },
  actions: {
    ToolbarDenseToggle: async (context) => {
      context.commit('TOOLBAR_DENSE_TOGGLE');
    },
    NavbarDenseToggle: async (context) => {
      context.commit('NAVBAR_DENSE_TOGGLE');
    },
    NavbarLogoToggle: async (context, payload) => {
      context.commit('NAVBAR_LOGO_TOGGLE', payload);
    },
    NavbarToggle: async (context, payload) => {
      context.commit('NAVBAR_TOGGLE', payload);
    },
    NavbarState: async (context, payload) => {
      context.commit('NAVBAR_STATE', payload);
    },
    FooterToggle: async (context) => {
      context.commit('FOOTER_TOGGLE');
    },
    DiscoverySearchData: async (context, payload) => {
      context.commit("DISCOVERY_SEARCH_DATA", payload)
    }
  },
};

export default settings;
