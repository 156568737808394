<template>
  <v-navigation-drawer
    fixed
    app
    :value="navbarShow"
    dark
    width="300"
    @input="stateNavbarShow"
  >
    <v-app-bar v-if="navbarLogo" :dense="toolbarDense" dark class="logo-nav">
      <!-- <v-toolbar-title class="text-center">
        <v-avatar tile class="company-logo">
          <img
            src="../../assets/logo-trackunit.png"
            class="incus5-logo"
            alt="trackunit-logo"
          />
        </v-avatar> -->
      <v-toolbar-title class="text-center logo-name adsaf">
        <v-avatar tile class="company-logo-mini">
          <img
            src="../../assets/trackunit-logo-white.svg"
            class="incus5-logo"
            alt="trackunit-logo"
          />
        </v-avatar>
        <span class="logo-name">Field Install Tool</span>
      </v-toolbar-title>
    </v-app-bar>
    <the-layout-drawer-list
      :dense="navbarDense"
      :routes="routesPath"
      icon-show
    />
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import TheLayoutDrawerList from "./TheLayoutDrawerList.vue";
import { adminRoutes } from "../../config/admin.route.config";
// import store from "../../store";
// import config from "../../config";
//import  { constantRoutes } from '@/router';

// const hasPermission = (role, route) => {
//   if (route.meta && route.meta.roles) {
//     return route.meta.roles.includes(role);
//   }
//   return true;
// };

// const filterAsyncRoutes = (roles, routes) => {
//   const res = [];
//   routes.forEach(route => {
//     const tmp = { ...route };
//     let pathId = "";
//     let isEnabled = true;
//     if (tmp.name) {
//       pathId = config.NAVIGATION[tmp.name];
//     }
//     if (pathId && store.getters.moduleToggleSettings) {
//       for(var i = 0; i<store.getters.moduleToggleSettings.length; i++) {
//         if (store.getters.moduleToggleSettings[i].id == pathId) {
//           isEnabled = store.getters.moduleToggleSettings[i].isEnabled;
//         }
//       }
//     }
//     if (isEnabled || roles=="Super Admin" || (roles=="Admin" && tmp.name=="Administration")) {
//       if (hasPermission(roles, tmp)) {
//         if (tmp.children) {
//           tmp.children = filterAsyncRoutes(roles, tmp.children);
//         }
//         res.push(tmp);
//       }
//     }
//   });
const hasPermission = (role, route) => {
  if (route.meta && route.meta.roles) {
    return route.meta.roles.includes(role);
  }
  return true;
};

const filterAsyncRoutes = (roles, routes) => {
  const res = [];
  routes.forEach((route) => {
    const tmp = { ...route };
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(roles, tmp.children);
      }
      res.push(tmp);
    }
  });
  return res;
};
// if (config.TRACKUNIT_CLIENT_ID != clientId) {
//   res.forEach((route, index) => {
//     if (route.name == "Report Requests") {
//       res.splice(index, 1);
//     }
//   });
// }
//   return res;
// };
export default {
  name: "TheLayoutDrawer",
  components: {
    TheLayoutDrawerList,
  },
  data: () => ({
    builds: [],
    routesPath: [],
    toolRoutes: [
      {
        name: "Status and Reporting",
        path: "/statusandreporting",
        toolId: "B4ADFA53-83C3-46FF-A158-F1CBDBB0E97A",
        meta: {
          roles: ["Manager", "User"],
          title: "Status and Reporting",
          icon: "mdi-checkbox-marked-outline",
        },
      },
      {
        name: "Equipment Records",
        path: "/equipmentrecords",
        toolId: "59EE7B1B-08AF-4616-82B9-C16815DB3B55 ",
        meta: {
          roles: ["Manager", "User"],
          title: "Equipment Records",
          icon: "mdi-map-marker-path",
        },
      },
      {
        name: "Service Records",
        path: "/servicerecords",
        toolId: "DE528079-DAD5-4736-B850-0D38A14E8202",
        meta: {
          roles: ["Manager", "User"],
          title: "Service Records",
          icon: "mdi-hammer-wrench",
        },
      },
    ],
    color: "dark",
  }),
  computed: {
    ...mapGetters([
      "navbarDense",
      "navbarShow",
      "navbarLogo",
      "toolbarDense",
      "roles",
      // "themeShow"
    ]),
  },
  created() {
    this.routesPath = filterAsyncRoutes(this.roles, adminRoutes);
    this.getToolsList();
    // switch(this.roles) {
    //   case 'Admin':
    //     this.routesPath = adminRoutes;
    //     break;
    //   case 'Install Manager':
    //     this.routesPath = adminRoutes;
    //     break;
    //   case 'Sales':
    //     this.routesPath = installAdminRoutes
    // }
  },
  mounted() {},
  methods: {
    stateNavbarShow(state) {
      this.$store.dispatch("NavbarState", { state });
    },
    getToolsList() {
      try {
        // let response = await apiService.getTools(this.userId);
        let response = this.$store.getters.toolIds;
        if (response.length>0) {
          let tempToolsList = response;
          var newToolsList = [];
          for (var k = 0; k < tempToolsList.length; k++) {
            newToolsList.push({
              index: k,
              toolId: tempToolsList[k],
            });
          }
          if (newToolsList && newToolsList.length > 0) {
            this.builds = this.toolRoutes;
          }
          //Filter Tools from Response
          let finalToolsList = [];

          for (var m = 0; m < this.toolRoutes.length; m++) {
            for (var x = 0; x < newToolsList.length; x++) {
              if (this.toolRoutes[m].toolId == newToolsList[x].toolId) {
                finalToolsList.push(this.toolRoutes[m]);
              }
            }
          }
          this.builds = finalToolsList;
          this.routesPath.splice(2, 0, ...this.builds);
          // this.routesPath.push(...this.builds);
        } 
        // else {
        //   this.systemMessage = "Tools for this Company is unavailable";
        //   // this.isBuildData = false;
        // }
      } catch (err) {
        // this.isBuildData = false;
        console.log("error", err);
      }
    },
  },
};
</script>
<style scoped>
.logo-name {
  /* font-size:18px; */
  padding-top: 8px;
}
/*.company-logo {
  height: 50px;
  width: 100% !important;
}*/
.company-logo-mini {
  height: 24px;
  width: 24px;
}
.logo-nav .v-toolbar__content {
  padding: 0px !important;
}
.v-toolbar__title {
  width: 100% !important;
}
/*.incus5-logo {
  height: 50px !important;
  max-width: 140px !important;
  object-fit: contain !important;
}*/
.incus5-logo {
  height: 24px !important;
  max-width: 24px !important;
  object-fit: contain !important;
}
.font-size-10 {
  font-size: 12px !important;
}
</style>
